import React from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift, faRecycle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const GiftItem = ({ item, currency }) => {
    const { t } = useTranslation();

    const isDeposit = item.tags.indexOf('drinkDeposit') > -1;
    const iTags = !isDeposit ? <FontAwesomeIcon icon={faGift} /> : <FontAwesomeIcon icon={faRecycle} />;
    const title = !isDeposit ? "darček" : "Z";

    return (
        <ListGroup.Item key={item.id} className="cart-item">
            <Row>
                <Col xs={2} className="cart-item-qty" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {item.quantity}x
                </Col>
                <Col xs={7}>
                    <Row>
                        <Col xs={7} className="cart-item-name">{t(item.name)}</Col>
                    </Row>
                    <Row>
                        <Col className="cart-item-description" xs={10}>{item.description}</Col>
                    </Row>
                </Col>
                <Col xs={3}>
                    <Row>
                        <Col className="discountIcon">{iTags} {title}</Col>
                    </Row>
                    <Row>
                        <Col className="cart-item-price">{(item.quantity * item.price).toFixed(2)} {currency}</Col>
                    </Row>
                </Col>

            </Row>

        </ListGroup.Item>
    );
};

export default GiftItem;