import React, { useState } from "react";
import { Button, Card, Form, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Rating from "react-rating";

const FeedbackScreen = () => {
    const { t } = useTranslation();

    const [ratingMessage, setRatingMessage] = useState();
    const [rating, setRating] = useState(0);
    const [message, setMessage] = useState("");

    const onRatingChange = (rate) => {
        setRatingMessage();
        setRating(rate);
    }

    const onFeedbackConfirm = () => {
        if (rating < 1) {
            setRatingMessage("Zadajte prosím vaše hodnotenie");
        }
        else {
            // TODO: add function to send feedback
            // TODO: redirect to homepage
            console.log({ rating, message })
        }
    }

    return (
        <Card className="order-status-success">
            <Card.Header className="rounded-0">{t("Spätná väzba").toUpperCase()}</Card.Header>
            <Card.Body>
                <Card.Text className="status-title">
                    {t("Váš názor je pre nás dôležitý").toUpperCase()} <br />
                </Card.Text>
                <Card className="status-message">
                    <>
                        <Rating className="mt-3 mb-4" initialRating={rating} onChange={v => onRatingChange(v)}
                            emptySymbol={<FontAwesomeIcon icon={faStar} className="mr-2" color="grey" size="3x" />}
                            fullSymbol={<FontAwesomeIcon icon={faStar} className="mr-2" color="gold" size="3x" />}
                        />

                        <Form.Group controlId="feedbackMessage">
                            <Form.Label>{t("Správa").toUpperCase()}</Form.Label>
                            <Form.Control as="textarea" rows="3" onChange={e => setMessage(e.target.value)} value={message}
                                placeholder={t("tu nám môžete zanechať správu...")} />
                        </Form.Group>

                    </>
                </Card>
            </Card.Body>
            <Card.Footer>
                <>
                    <Col xs={12} style={{ fontSize: "10px", fontWeight: "bold", color: "red", textAlign: "center" }}>
                        {ratingMessage}
                    </Col>

                    {/* <a href={process.env.REACT_APP_HOME_PAGE}> */}
                    <Button onClick={() => onFeedbackConfirm()} variant="light" className="button-secondary">
                        {t("Odoslať").toUpperCase()}
                    </Button>
                    {/* </a> */}
                </>
            </Card.Footer>
        </Card>
    );
}

export default FeedbackScreen;


