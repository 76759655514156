import React from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const GiftOverviewItem = ({ item, currency }) => {
    const { t } = useTranslation();

    return (
        <ListGroup.Item key={item.id} className="cart-item">
            <Row>
                <Col xs={2} className="cart-item-qty">
                    {item.quantity}x
                </Col>
                <Col xs={7} className="cart-item-name">{t(item.name)}</Col>
                <Col xs={3}>
                    <Row>
                        <Col className="cart-item-price">{item.price.toFixed(2)} {currency}</Col>
                    </Row>
                </Col>
            </Row>
        </ListGroup.Item>
    );
};

export default GiftOverviewItem;



