import React, { useEffect, useState } from 'react';
import Gastrofy from "./Gastrofy";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/custom/css/main.css"
import { useTranslation } from "react-i18next";


////////
// TODO: Load TagManager only if REACT_APP_GTM_ID is non empty string
import TagManager from 'react-gtm-module'
const gtmId = `${process.env.REACT_APP_GTM_ID}`;
if (gtmId) {
  TagManager.initialize({ gtmId });
}
////////

function App() {
  const { t } = useTranslation();

  function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 1000)

    window.addEventListener('resize', debouncedHandleResize)

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  });

  return (
    <div className="gastrofy-orderApp">
      <Gastrofy translations={t} dimensions={dimensions} />
    </div>
  );
}

export default App;
