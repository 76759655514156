export const PRODUCT_LIST = "productListScreen";
export const CLOSED_SHOP = "closedShopScreen";
export const ORDER_SUMMARY = "orderSummaryScreen";
export const CART = "cartScreen";
export const CONTACT_FORM = "contactFormScreen";
export const ORDER_STATUS = "orderStatusScreen";
export const PAYMENT_STATUS = "paymentStatusScreen";
export const FEEDBACK = "feedBackScreen";





