import React from "react";
import Scrollspy from "react-scrollspy"
import { useTranslation } from "react-i18next";


const CategoryScrollSpyNavbar = (props) => {
  const { t } = useTranslation();

  const categoryNames = props.categoryNames;
  return (
    <Scrollspy
      className="scrollspy categories-bar"
      items={Object.keys(categoryNames)}
      currentClassName="active"
    >
      {Object.keys(categoryNames).map((k) => (
        <li key={k}><a href={"#" + [k]}>{t(categoryNames[k]).toUpperCase()}</a></li>
      ))}
    </Scrollspy>
  );
};

export default CategoryScrollSpyNavbar;