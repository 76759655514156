export const requiredPriceForDelivery = {
  message: "Doručenie kuriérom možné iba pri nákupe nad"
};

export const alergens = [
  { id: 1, value: "Obilniny obsahujúce glutén (lepok)" },
  { id: 2, value: "Kôrovce a výrobky z kôrovcov" },
  { id: 3, value: "Vajcia a výrobky z vajec" },
  { id: 4, value: "Ryby a výrobky z rýb" },
  { id: 5, value: "Arašidy a výrobky z arašidov" },
  { id: 6, value: "Sójové bôby a výrobky zo sójových bôbov" },
  { id: 7, value: "Mlieko a mliečne výrobky (vrátane laktózy)" },
  { id: 8, value: "Orechy" },
  { id: 9, value: "Zeler a výrobky zo zeleru" },
  { id: 10, value: "Horčica a výrobky z horčice" },
  { id: 11, value: "Sezamové semeno a výrobky zo sezamových semien" },
  { id: 12, value: "Oxid siričitý a siričitany" },
  { id: 13, value: "Vlčí bôb a výrobky z vlčieho bôbu" },
  { id: 14, value: "Mäkkýše a výrobky z mäkkýšov" },
];

export const exceptionMessages = {
  emptyReqieredData: "Neboli vyplnené všetky povinné údaje",
  invalidEmail: "Nesprávne vyplnený email",
  invalidPhoneNumber: "Zadajte korektne telefónne číslo",
  ivalidAddress: "Nesprávne vyplnená adresa",
  ivalidPrice: "Niekde nastala chyba prosím spravte novú objednávku alebo nám zavolajte",
  emptyName: "Zadajte meno",
  emptyLastName: "Zadajte priezvisko",
  shopClosed: "Objednávku ste žial vytvorili mimo prevázkových hodín",
  emptyAddress: "Zadajte Adresu doručenia",
  defaultOrderException: "Niekde nastala chyba prosím spravte novú objednávku alebo nám zavolajte",
  connectionFailed: "Spojenie zlyhalo - skontrolujte internetové pripojenie a skúste znovu",
  missingPaymentType: "Zvoľte prosím spôsob platby"
};


