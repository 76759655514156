import React from "react";
// import * as screenList from "../../data/appScreenList";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const OrderSuccessfulScreen = ({ switchComponent }) => {
    const { t } = useTranslation();

    return (
        <>
            <Card className="order-status-success">
                <Card.Header className="rounded-0">{t("Potvrdenie objednávky").toUpperCase()}</Card.Header>
                <Card.Body>
                    <Card.Text className="status-title">
                        {t("Vaša objednávka bola úspešne odoslaná").toUpperCase()} <br />
                        {t("Ďakujeme!").toUpperCase()}
                    </Card.Text>
                    <Card.Text className="status-message">
                        {t("Na Váš email sme odoslali potvrdenie.")} <br />
                        {t("Budeme Vás kontaktovať telefonicky.")}
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <a href={process.env.REACT_APP_HOME_PAGE}>
                        <Button variant="light" className="button-secondary">
                            {t("OK").toUpperCase()}
                        </Button>
                    </a>
                </Card.Footer>
            </Card>
            {/* <p className="feedback-link" onClick={() => switchComponent(screenList.FEEDBACK)} >
                {t("Mali ste probllémy pri zadaní objednávky? Zanechajte nám spätnú väzbu")} <span>{t("tu")} </span>
            </p> */}

        </>
    );
}

export default OrderSuccessfulScreen;