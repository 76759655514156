import React, { Component } from "react";
import { Form, Col, ListGroup, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

const SuggestedAddress = (props) => {
    return (
        <ListGroup.Item key={props.address.id} className="suggested-address" onClick={() => props.setAddressValues(props.address.id)}>
            {props.address.value}
        </ListGroup.Item>
    )
};

const SuggestedAddressList = (props) => {
    const addressList = props.addressList;

    let addresses = addressList.map((address) => {
        const { id } = address;
        return (
            <SuggestedAddress
                key={id}
                address={address}
                setAddressValues={props.setAddressValues}
                searchedAddress={props.searchedAddress}
            />
        )
    })
    return (
        <ListGroup className="autosuggestion-openedList" style={{ display: props.dsiplayAddressSuggestionsOnFocus }}>
            {addresses}
        </ListGroup>
    )
};

class AddressAutosuggest extends Component {
    constructor() {
        super();
        this.state = {
            addressList: [],
            searchedAddress: "",
            loadingAddressList: false,
            dsiplayAddressSuggestionsOnFocus: "none"
        };
    }

    getAddressList = (address) => {
        const req = new Request(`${process.env.REACT_APP_BACKEND}/search?query=${address}`, {
            method: "GET",
            cache: "default"
        });
        fetch(req).then(response => {
            return response.json();
        }).then(data => {
            this.setState({ addressList: data });
            // console.log(data)
        }).catch(err => {
            console.log("ERROR: " + err);
        })
        this.setState({ loadingAddressList: false });
    };

    onSearchAddress = (e) => {
        const searchedAddress = e.target.value;
        if (searchedAddress.length > 2) {
            this.setState({ loadingAddressList: true });
            setTimeout(() => this.getAddressList(searchedAddress), 250);
        }
        this.setState({ searchedAddress });
    };

    onFocusOut = () => {
        setTimeout(() => this.setState({ dsiplayAddressSuggestionsOnFocus: "none" }), 150);
        // setTimeout(() => this.setState({ searchedAddress: "" }), 150);
    }

    render() {
        const t = this.props.translations;
        let streetValue = this.props.selectedAddress.street;
        let postCodeValue = this.props.selectedAddress.postCode;
        let cityValue = this.props.selectedAddress.city;
        return (
            <>
                <Form.Row>
                    <Form.Group as={Col} controlId="text" className="autosuggestion-container">
                        {this.props.selectedAddress.id > 0 ?
                            <>
                                <Form.Row>
                                    <Form.Group
                                        as={Col}
                                        controlId="street"
                                        style={{ marginBottom: "0px" }}
                                        onClick={() => this.props.clearAddressValues()}
                                    >
                                        <Form.Label >
                                            {t("Adresa Doručenia").toUpperCase()}
                                        </Form.Label>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            value={streetValue}
                                            disabled={true}
                                            style={{ backgroundColor: this.props.selectedAddress.isShipping ? "#ffffff" : "#f8d7da" }}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group
                                        as={Col}
                                        onClick={() => this.props.clearAddressValues()}
                                    >
                                        <Form.Control
                                            id="input-city"
                                            size="sm"
                                            type="text"
                                            value={cityValue + "-" + postCodeValue}
                                            disabled={true}
                                            style={{ backgroundColor: this.props.selectedAddress.isShipping ? "#ffffff" : "#f8d7da" }}
                                        />
                                    </Form.Group>
                                </Form.Row>

                                {
                                    (!this.props.selectedAddress.isShipping) &&
                                    <p style={{ fontSize: "10px", fontWeight: "bold", color: "red", textAlign: "left", marginTop: "-15px" }}>
                                        {t("Na uvedenú adresu nedoručujeme")}
                                    </p>
                                }
                            </> :

                            <>
                                <Form.Label >
                                    {t("Adresa Doručenia").toUpperCase()} {" "}
                                    {this.state.loadingAddressList &&
                                        <Spinner animation="border" variant="light" className="mr-3" size="sm" />
                                    }
                                </Form.Label> <br />

                                <>
                                    <Form.Control
                                        onFocus={() => this.setState({ dsiplayAddressSuggestionsOnFocus: "block" })}
                                        onBlur={() => this.onFocusOut()}
                                        size="sm"
                                        type="text"
                                        value={this.state.searchedAddress}
                                        onChange={e => this.onSearchAddress(e)}
                                        autoComplete="new-password"
                                    />
                                    <div style={{ float: "right", cursor: "pointer", marginLeft: 7 }}>
                                        <FontAwesomeIcon onClick={() => this.setState({ searchedAddress: "" })} className="mr-2" icon={faTimes} />

                                    </div>

                                </>
                            </>
                        }

                        {/* {(!this.props.selectedAddress.isShipping && this.props.selectedDeliveryType.name === "Osobné vyzdvihnutie na prevádzke") &&
                            <p style={{ fontSize: "11px", fontWeight: "bold" }}>
                                {<FontAwesomeIcon className="mr-2" icon={faInfoCircle} />}
                                V prípade osobného odberu možete zadať našu adresu (Kresánkova 7A)
                            </p>
                        } */}

                        {(this.state.searchedAddress.length > 2 &&
                            this.state.addressList.length === 0 &&
                            !this.state.loadingAddressList) &&
                            <p style={{ fontSize: "12px", fontWeight: "bold", color: "red" }}>
                                {<FontAwesomeIcon className="mr-2" icon={faInfoCircle} />}
                                {t("Zadaná adresa sa nenašla v zozname")}
                            </p>
                        }

                        {(this.state.searchedAddress.length > 2 && this.state.addressList.length > 0) &&
                            <SuggestedAddressList
                                dsiplayAddressSuggestionsOnFocus={this.state.dsiplayAddressSuggestionsOnFocus}
                                addressList={this.state.addressList}
                                setAddressValues={this.props.setAddressValues}
                                searchedAddress={this.state.searchedAddress}
                            />
                        }
                    </Form.Group>
                </Form.Row>
            </>
        )
    }
}


export default AddressAutosuggest;
