import React, { useState } from "react";
import { Col, ListGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const SingleAddon = (props) => {
  const { t } = useTranslation();

  const iPlus = <FontAwesomeIcon icon={faPlus} />;
  const iMinnus = <FontAwesomeIcon icon={faMinus} />;

  const addon = props.addon;
  const [counter, setCounter] = useState(0);
  return (
    <ListGroup.Item className="single-addon">
      <ListGroup horizontal className={counter > 0 && "addon-added"}>
        <Col xs={1}>{(counter > 0) ? <>{counter}x</> : false}</Col>
        <Col xs={4} style={{ textAlign: "left" }}>{t(addon.name)}</Col>
        <Col xs={2} style={{ textAlign: "right" }}>{addon.price.toFixed(2)}{props.currency}</Col>
        <Col xs={5}>
          <Button
            variant="secondary"
            className={(counter > 0) ? "button-minus mr-1" : "button-minus-disabled mr-1"}
            disabled={(counter === 0) && true}
            size="sm" onClick={() => setCounter(counter - 1) + props.changeAddonQty("remove", addon)}
          >{iMinnus}</Button>
          <Button
            variant="secondary"
            className="mr-1 button-plus"
            size="sm"
            onClick={() => setCounter(counter + 1) + props.changeAddonQty("add", addon)}
          >{iPlus}</Button>
        </Col>
      </ListGroup>
    </ListGroup.Item>
  );
};

const AddonsList = (props) => {
  const productsAddons = props.productsInfo.addons.map((addon) => {
    const { id } = addon;
    return (
      <SingleAddon
        addon={addon}
        key={id}
        changeAddonQty={props.changeAddonQty}
        currency={props.currency}
      />
    )
  });
  return <>{productsAddons}</>
};

export default AddonsList;