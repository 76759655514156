import React, { useState } from "react";
import { Jumbotron, Col, Modal, Card, Button } from "react-bootstrap";
import logo from "../../custom/images/logo.svg";
import { useTranslation } from "react-i18next";
import AlergenList from "../common/Alergens";
import { companyDetails } from "../../custom/data/dataCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";

const Header = (props) => {
    const [showAlergens, setShowAlergens] = useState(false);
    const handleCloseAlergens = () => setShowAlergens(false);
    const handleShowAlergens = () => setShowAlergens(true);
    //TODO: ADD TRANSLATIONS FROM BACKEND ANE FINISH LANGUAGE
    // const { t, i18n } = useTranslation();
    const { t } = useTranslation();

    // function changeLanguage(lang) {
    //     i18n.changeLanguage(lang);
    // }

    return (
        <>
            <Jumbotron className="header rounded-0 pb-3">
                <div className="title">
                    <img src={logo} alt="" className="logo" width="70" height="70" loading="lazy" />
                    <h5 className="header-title"> {t("Online Objednávka".toUpperCase())}</h5>
                </div>
                <ul className="navbar">

                    <li className="link-home navbar-li">
                        <a href="/">
                            {t("DOMOV")}
                        </a>
                    </li>

                    <li onClick={() => handleShowAlergens()} className="alergens-legend navbar-li ml-2">
                        {t("Alergény").toUpperCase()}
                    </li>

                    <li className="header-phone navbar-li ml-2">
                        <a className="phone-number" href={`tel:${companyDetails.phone}`}>
                            <FontAwesomeIcon icon={faPhoneVolume} /> {companyDetails.phone}
                        </a>
                    </li>

                </ul>
            </Jumbotron>

            <Modal
                show={showAlergens}
                onHide={handleCloseAlergens}
                animation={false}
                backdrop="static"
                keyboard={false}
                className="alergens-list"
            >
                <Card.Header className="rounded-0">{t("Zoznam alergénov").toUpperCase()}</Card.Header>
                <Modal.Body className="alergen-list-body">
                    <AlergenList />
                </Modal.Body>
                <Modal.Footer className="alergen-list-footer">
                    <Col xs={12}>
                        <Button className="button-secondary" variant="light" onClick={handleCloseAlergens}>
                            {t("Zavrieť").toUpperCase()}
                        </Button>
                    </Col>
                </Modal.Footer>
            </Modal>
        </>

    );
}

export default Header;
