import React from "react";
import {
  Col,
  Row,
  Card,
  ButtonGroup,
  ToggleButton,
  Alert,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDotCircle } from "@fortawesome/fontawesome-free-regular";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { requiredPriceForDelivery } from "../../data/Data";
import * as customData from "../../custom/data/dataCustom";
import { useTranslation } from "react-i18next";

const PaymentAndDeliveryScreen = (props) => {
  const iCheckCircle = <FontAwesomeIcon icon={faDotCircle} />;
  const iCircle = <FontAwesomeIcon icon={faCircle} />;
  const iExclametion = <FontAwesomeIcon icon={faExclamationCircle} />;

  const { t } = useTranslation();

  function messageHandler(typeName, type) {
    switch (typeName) {
      case "delivery":
        if (type.id === "Currier")
          return type.isDeliveryAllowed ? (
            `${t("Približne")} ${props.averageDeliveryTimeInMinutes} ${t(
              "minút"
            )}`
          ) : (
            <span
              style={{
                fontSize: "10px",
                fontWeight: "900",
                color: "red",
                textAlign: "left",
                marginTop: "-15px",
              }}
            >{`${t(
              requiredPriceForDelivery.message
            )} ${type.minOrderPrice.toFixed(2)}${props.currency}`}</span>
          );

        if (type.id === "Pickup") return customData.companyDetails.address;

        break;

      case "payment":
        if (type.id === "PayOnDelivery")
          return t(customData.pageInfoMessage.paymentAndDeliveryOptions);
        break;

      default:
        return;
    }

    return "";
  }

  return (
    <Card className="payment-delivery rounded-0">
      <Card.Header className="rounded-0 mb-3">
        {t("Platba a doručenie").toUpperCase()}
      </Card.Header>

      {!props.selectedPaymentType.id && props.paymentAndDeliveryException && (
        <Alert variant="danger">
          {iExclametion} {t(props.paymentAndDeliveryException)}
        </Alert>
      )}

      <div className="payment-delivery-conatiner">
        <h6>{t("Spôsob doručenia").toUpperCase()}</h6>
        <ButtonGroup toggle>
          {props.deliveries.map((type, i) => (
            <ToggleButton
              className="float-left mb-2"
              variant="secondary"
              key={i}
              type="radio"
              name="delivery"
              disabled={!type.isDeliveryAllowed}
              value={type.id}
              checked={props.selectedDeliveryType.id === type.id}
              onChange={(e) => props.setDeliveryType(e.currentTarget.value)}
            >
              <Row>
                <Col xs={1}>
                  {props.selectedDeliveryType.id === type.id
                    ? iCheckCircle
                    : iCircle}
                </Col>
                <Col xs={7}>{type.name}</Col>
                <Col xs={3}>{type.price.toFixed(2) + " " + props.currency}</Col>
              </Row>
              <Row>
                <Col xs={1}></Col>
                <Col xs={7} className="payment-delivery-message">
                  {messageHandler("delivery", type)}
                </Col>
                <Col xs={3}></Col>
              </Row>
            </ToggleButton>
          ))}
        </ButtonGroup>

        <h6>{t("Spôsob platby").toUpperCase()}</h6>
        <ButtonGroup toggle>
          {props.payments.map((type, i) => (
            <ToggleButton
              className="float-left mb-2"
              variant="secondary"
              key={i}
              type="radio"
              name="payment"
              value={type.id}
              checked={props.selectedPaymentType.id === type.id}
              onChange={(e) => props.setPaymentType(e.currentTarget.value)}
            >
              <Row>
                <Col xs={1}>
                  {props.selectedPaymentType.id === type.id
                    ? iCheckCircle
                    : iCircle}
                </Col>
                <Col xs={7}>
                  {type.id === "PayOnDelivery"
                    ? "Platba u kuriéra"
                    : "Online platba"}
                </Col>
                <Col xs={3}>{type.price.toFixed(2) + " " + props.currency}</Col>
              </Row>
              <Row>
                <Col xs={1}></Col>
                <Col xs={7} className="payment-delivery-message">
                  {messageHandler("payment", type)}
                </Col>
                <Col xs={3}></Col>
              </Row>

              {(process.env.REACT_APP_SPLIT_PAY_ON_DELIVERY && type.id === "PayOnDelivery") &&
                <Row>
                  <Col xs={1}></Col>
                  <Col xs={11}>
                    <ButtonGroup toggle>
                      {customData.payOnDeliveryOptions.map((type, i) => (
                        <ToggleButton
                          className="float-left mb-2"
                          variant="secondary"
                          key={i}
                          type="radio"
                          name="deliveryOption"
                          value={type}
                          checked={props.selectedDeliveryOption === type}
                          onChange={(e) =>
                            props.setDeliveryOption(e.currentTarget.value)
                          }
                        >
                          <Row>
                            <Col xs={1}>
                              {props.selectedDeliveryOption === type
                                ? iCheckCircle
                                : iCircle}
                            </Col>
                            <Col xs={10}>{type}</Col>
                          </Row>
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </Col>
                </Row>
              }
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>
    </Card>
  );
};

export default PaymentAndDeliveryScreen;
