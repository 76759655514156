export const currency = {
  name: "EUR",
  display: "€",
};

export const companyDetails = {
  address: "Kresánkova 7A, 841 05 Bratislava",
  phone: "0948 166 900",
  email: "pizzaviktoria.sk@gmail.com",
  company: "DAMACOM s. r. o., Košická 49, 821 08 Bratislava",
};

export const pageInfoMessage = {
  productListScreen: "Priemerná dĺžka doručenia",
  productListScreenPreorder: "Predobjednávka - bude doručená prvým rozvozom",
  cart: "Pri nákupe nad 23€ dostanete darček",
  paymentAndDeliveryOptions: "Možnosti: hotovosť, platba kartou, gastrolístok",
  messageOutOfHours: "Navštívili ste našu reštauráciu mimo otváracích hodín.",
};

export const payOnDeliveryOptions = [
  "Kuriér - hotovosť",
  "Kuriér - platba kartou",
];
