import React from "react";
import * as screenList from "../../data/appScreenList";
import CartItem from "../cart/CartItem";
import CartDiscountItem from "../cart/CartDiscountItem";
import CartGiftItem from "../cart/CartGiftItem";
import { Button, ListGroup, Card, Col, Row } from "react-bootstrap";
import EmptyCartPicture from "../../images/emptycart.png";
import { useTranslation } from "react-i18next";
import InfoPanel from "../common/InfoPanel";

const CartScreen = (props) => {
  const { t } = useTranslation();

  const inCart = props.inCart;

  const serviccePrice = props.selectedDeliveryType.price + props.selectedPaymentType.price;
  const regularItems = inCart && inCart.filter(prod => !prod.tags || (!prod.tags.includes("discount") && (!prod.tags.includes("gift") && (!prod.tags.includes("drinkDeposit")))));
  const inCartDiscounted = inCart && inCart.filter(prod => prod.tags && prod.tags.includes("discount"));
  const inCartGift = inCart && inCart.filter(prod => prod.tags && prod.tags.includes("gift"));
  const inCartDeposit = inCart && inCart.filter(prod => prod.tags && prod.tags.includes("drinkDeposit"));

  let item;

  let items = regularItems && regularItems.map((it, i) => {
    item = it;
    return (
      <CartItem
        item={item}
        key={i}
        changeProductQty={props.changeProductQty}
        currency={props.currency}
      />
    );
  });

  let discItems = inCartDiscounted && inCartDiscounted.map((disc, i) => {
    const { name } = disc;
    return (
      <CartDiscountItem
        item={disc}
        key={name}
        changeProductQty={props.changeProductQty}
        currency={props.currency}
      />
    );
  });

  let giftItems = inCartGift && inCartGift.map((disc, i) => {
    const { name } = disc;
    return (
      <CartGiftItem
        item={disc}
        key={name}
        currency={props.currency}
      />
    );
  });

  let depositItems = inCartDeposit && inCartDeposit.map((disc, i) => {
    const { name } = disc;
    return (
      <CartGiftItem
        item={disc}
        key={name}
        currency={props.currency}
      />
    );
  });

  const screenWidth = props.screenWidth;

  return (
    <Card className="cart rounded-0">
      <Card.Header className="rounded-0">{t("Košík").toUpperCase()}</Card.Header>

      {(screenWidth >= 1024) &&
        <InfoPanel
          renderedComponent={screenList.CART}
        />
      }

      {props.infoPanel}

      {(inCart.length > 0) ? (
        <div className="cart-content">
          <ListGroup variant="flush">
            {items}
            {discItems}
            {giftItems}
            {depositItems}
          </ListGroup>
        </div>
      ) : (
        <>
          <img src={EmptyCartPicture} alt="EmptyCart" />
        </>
      )}
      {(screenWidth >= 1024) &&
        <>
          <Col className="price">
            <Row>
              <Col className="price-title">
                {t("Spolu").toUpperCase()}:
              </Col>
            </Row>
            <Row>
              <Col className="price-sum">
                {(inCart.length > 0) ? (props.price - serviccePrice).toFixed(2) : (0).toFixed(2)} {props.currency}
              </Col>
            </Row>
          </Col>

          <Col className="button-container">
            <Button
              className={(inCart.length === 0) ? "button-secondary" : "button-primary"}
              variant="light"
              onClick={() => (inCart.length > 0) && props.switchComponent(screenList.ORDER_SUMMARY)}>
              {(inCart.length === 0) ? t("Košík je prázdny").toUpperCase() : t("Objednať").toUpperCase()}
            </Button>
          </Col>
        </>
      }
    </Card>
  );
};

export default CartScreen;
