import React from "react";
import * as screenList from "../../data/appScreenList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserClock } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { pageInfoMessage } from "../../custom/data/dataCustom";

const InfoPanel = ({
  renderedComponent,
  averageDeliveryTimeInMinutes,
  outOfOrderCurrently,
  outOfOrder,
  preOrderAllowed,
  isOpened,
  deliveryRegionList }) => {

  const { t } = useTranslation();

  const showPreorderMessage = !isOpened && preOrderAllowed;

  const iUserClock = <FontAwesomeIcon icon={faUserClock} />;

  function getMessage(cmp) {
    switch (cmp) {
      case screenList.PRODUCT_LIST:
        return showPreorderMessage ? (<p>{iUserClock} {t(pageInfoMessage.productListScreenPreorder).toUpperCase()}</p>) :
          (<p>{iUserClock} {t(pageInfoMessage.productListScreen).toUpperCase()} {averageDeliveryTimeInMinutes} {t("minút")}</p>)
      case screenList.ORDER_SUMMARY:
        return <p>{deliveryRegionList && deliveryRegionList.join(" - ").toUpperCase()}</p>;
      case screenList.CONTACT_FORM:
        return <p>{deliveryRegionList && deliveryRegionList.join(" - ").toUpperCase()}</p>;
      case screenList.CART: return <p>{t(pageInfoMessage.cart).toUpperCase()}</p>; //TODO:ADD message from backend
      case screenList.CLOSED_SHOP:
        return <p>{(!outOfOrderCurrently) ? t(pageInfoMessage.messageOutOfHours).toUpperCase() : t(outOfOrder.message).toUpperCase()} </p>;
      default: return null;
    }
  }
  return (
    <div className="info-panel">
      {getMessage(renderedComponent)}
    </div>
  );
}

export default InfoPanel;
