import React from "react";

const SelectedVariation = ({ variations }) => {
    const variationNames = [];

    variations.forEach(v => {
        variationNames.push(v.name);
    });

    return (
        <>
            {variationNames.join(", ")}
        </>
    );
};

export default SelectedVariation;