import React from "react";
import { useTranslation } from "react-i18next";

const OrderedAddon = (props) => {
    const { t } = useTranslation();

    const addon = props.addon;
    return (
        <li>
            {addon.quantity + "x "} {t(addon.name) + ", "}
        </li>
    );
};

export default OrderedAddon;