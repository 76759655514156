import React from "react";
import OrderedAddon from "./OrderedAddon";
import SelectedVariation from "./SelectedVariation";
import ProductAllergenList from "../common/ProductAlergensList";
import { Row, Col, ListGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import * as commonFunctions from "../../functions/commonFunctions";

const DiscountedItem = ({ item, changeProductQty, currency }) => {
    const { t } = useTranslation();

    let addon;
    const addons = (item.addons) && item.addons.map((a, i) => {
        addon = a;
        return (
            <OrderedAddon key={i} addon={addon} />
        )
    });

    const iMinus = <FontAwesomeIcon icon={faMinus} />;
    const iPlus = <FontAwesomeIcon icon={faPlus} />;
    const iTags = <FontAwesomeIcon icon={faTags} />;

    return (
        <ListGroup.Item key={(item.id) ? item.tempId : item.id} className="cart-item">
            <Row>
                <Col xs={2} className="cart-item-qty" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {item.quantity}x
                </Col>
                <Col xs={7} style={{ borderBottom: item.addons.length > 0 ? "solid rgb(158, 158, 158) 2px" : "none" }}>
                    <Row>
                        <Col className="cart-item-name">{t(item.name)}</Col>
                    </Row>
                    <Row>
                        <Col className="cart-item-description">{t(item.description)}</Col>
                    </Row>
                    <Row>
                        <Col className="cart-item-alergens">
                            <ProductAllergenList allergens={item.allergens} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={3}>
                    <Row>
                        <Col className="cart-item-buttons">
                            <Button
                                size="sm"
                                className="button-minus mr-1"
                                variant="light"
                                onClick={() => changeProductQty("remove", item)}
                            >{iMinus}</Button>
                            <Button
                                size="sm"
                                className="button-plus"
                                variant="light"
                                onClick={() => changeProductQty("increase", item)}
                            >{iPlus}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="discountIcon">{iTags} {t("akcia")}</Col>
                    </Row>
                    <Row>
                        <Col className="cart-item-price">
                            {commonFunctions.getProductPrice(item, item.quantity)} {currency}
                        </Col>
                    </Row>
                </Col>
            </Row>

            {(item.variationGroups && item.variationGroups.length > 0) &&
                item.variationGroups.map(group => {
                    return (
                        <Row>
                            <Col xs={2}></Col>
                            <Col xs={10} className="cart-variations">{group.name}: <SelectedVariation variations={group.variations} /></Col>
                        </Row>
                    )
                })
            }

            {(item.addons && item.addons.length > 0) &&
                <Row>
                    <Col xs={2}>{iPlus}</Col>
                    <Col xs={7} className="addons"><ul>{addons}</ul></Col>
                </Row>
            }
        </ListGroup.Item>
    );
};

export default DiscountedItem;