import React from "react";
import { Col, Row } from "react-bootstrap";
import CartOverview from "../cart/CartOverview";
import PaymentAndDeliveryScreen from "../screens/PaymentAndDeliveryScreen";
import ContactFormScreen from "../screens/ContactFormScreen";

const OrderSummaryScreen = (props) => {
  const screenWidth = props.screenWidth;
  return (
    <div className="order-summary-screen">
      <Row>
        {screenWidth >= 1024 && (
          <Col xs={6}>
            <CartOverview
              inCart={props.inCart}
              price={props.price}
              currency={props.currency}
              selectedDeliveryType={props.selectedDeliveryType}
              selectedPaymentType={props.selectedPaymentType}
            />
          </Col>
        )}

        <Col xs={screenWidth >= 1024 ? 6 : 12}>
          {screenWidth >= 1024 && (
            <ContactFormScreen
              setLocalStorageItem={props.setLocalStorageItem}
              localFormData={props.localFormData}
              selectedDeliveryType={props.selectedDeliveryType}
              selectedPaymentType={props.selectedPaymentType}
              formException={props.formException}
              getLocalFormData={props.getLocalFormData}
              setAddressValues={props.setAddressValues}
              selectedAddress={props.selectedAddress}
              clearAddressValues={props.clearAddressValues}
              infoPanel={props.infoPanel}
            />
          )}

          {props.selectedAddress.id > 0 && (
            <PaymentAndDeliveryScreen
              deliveries={props.deliveries}
              payments={props.payments}
              setDeliveryType={props.setDeliveryType}
              setPaymentType={props.setPaymentType}
              selectedDeliveryType={props.selectedDeliveryType}
              selectedPaymentType={props.selectedPaymentType}
              currency={props.currency}
              isDeliveryAllowed={props.isDeliveryAllowed}
              averageDeliveryTimeInMinutes={props.averageDeliveryTimeInMinutes}
              paymentAndDeliveryException={props.paymentAndDeliveryException}
              selectedDeliveryOption={props.selectedDeliveryOption}
              setDeliveryOption={props.setDeliveryOption}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OrderSummaryScreen;
