import React from "react";
import SingleProduct from "./SingleProduct";
import { ListGroup, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const SingleCategoryItems = (props) => {
  let products = props.filteredProducts.map((product, i) => {
    const { id } = product;
    return (
      <SingleProduct
        product={product}
        key={i}
        id={id}
        changeProductQty={props.changeProductQty}
        currency={props.currency}
      />
    );
  });
  return (
    <ListGroup className="single-category-productList" variant="flush">
      {products}
    </ListGroup>
  );
};

const CategorySections = (props) => {
  const { t } = useTranslation();

  const allCategories = props.categories;

  const filterProducts = (categories, categoryName) => {
    const category = categories.filter(cat => cat.name === categoryName)[0];

    const categoryProducts = category.products;

    categoryProducts.forEach(product => {
      const uniqProductAddons = [...category.addons];

      if (product.addons.length > 0) {
        product.addons.forEach(addon => {
          const duplicates = uniqProductAddons.filter(a => a.id === addon.id);

          if (duplicates.length < 1) uniqProductAddons.push(addon);
        });
      }

      product.categoryName = categoryName;
      product.productsInfo = {
        category: categoryName,
        hasAddons: (uniqProductAddons.length > 0) ? true : false, addons: uniqProductAddons
      }

    })

    return categoryProducts;
  }

  return (
    <div className="category-sections">
      {Object.keys(props.categoryNames).map((k) => (
        <section id={[k]} key={k} className="single-category-section">
          <Alert
            className="category-title rounded-0"
            key={[k]}
            variant="light"
          >
            {t(props.categoryNames[k]).toUpperCase()}
          </Alert>
          <SingleCategoryItems
            filteredProducts={filterProducts(allCategories, k)}
            changeProductQty={props.changeProductQty}
            currency={props.currency}
          />
        </section>
      ))}
    </div>
  );
};

export default CategorySections;