import React from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import OrderSuccessfulScreen from "../screens/OrderSuccessfulScreen";
import Footer from "../common/Footer";
import { useTranslation } from "react-i18next";

const OrderFailedScreen = (props) => {
    const { t } = useTranslation();

    return (
        <Card className="order-status-fail">
            <Card.Header className="rounded-0">{t("Potvrdenie objednávky").toUpperCase()}</Card.Header>
            <Card.Body>
                <Card.Text className="status-title">
                    {t(props.orderException)}
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <Button variant="light" className="button-secondary" onClick={() => window.location.reload()}>
                    {t("OK")}
                </Button>
            </Card.Footer>
        </Card>
    );
}

const SinglePaymentDataInput = (props) => {
    return <input type="hidden" name={props.name} value={props.value} />
};

const SinglePaymentRequest = (props) => {
    const { t } = useTranslation();

    return (
        <form action={props.action} method={props.method} encType="application/x-www-form-urlencoded">
            {
                props.data.map(data =>
                    <SinglePaymentDataInput key={data.key} name={data.key} value={data.value} />
                )}
            <Button type="submit" variant="light" className="button-primary">{t("Zaplatiť").toUpperCase()}</Button>
        </form>
    );
};

const PaymentRequests = (props) => {
    return (
        <Col xs={4}>
            {
                props.requests.map((request, index) =>
                    <SinglePaymentRequest key={index} {...request} />
                )
            }
        </Col>
    );
};


const ToOnlinePaymentScreen = (props) => {
    const { t } = useTranslation();

    const paymentData = props.paymentData;

    const getPriceFromPaymentData = () => {
        return parseFloat(paymentData.amount).toFixed(2);
    };

    return (
        <>
            <Card className="to-online-payment rounded-0 pb-3">
                <Card.Header className="rounded-0 mb-2">{t("Online Platba").toUpperCase()}</Card.Header>

                <Card.Body>
                    <Card.Text>
                    </Card.Text>
                    <Card.Text className="status-title">
                        {t("Vaša objednávka bola vytvorená a čaká na online platbu").toUpperCase()} <br />
                    </Card.Text>
                    <Card.Text className="status-submessage">
                        {t("Vyžaduje to platobná brána,")} <br />
                        {t("budete presmerovaný na platobnú bránu")} <br />
                    </Card.Text>
                </Card.Body>

                <Card.Footer>
                    <Row className="button-container pb-1">
                        <Col xs={4}>
                            <Button variant="light" className="button-secondary" onClick={() => window.location.reload()}>{t("Zrušiť").toUpperCase()}</Button>
                            {/* <Button variant="success" className="button-secondary" onClick={() => props.switchComponent(screenList.ORDER_SUMMARY)}>{t("Zrušiť")}</Button> */}
                        </Col>
                        <Col>
                            <Row>
                                <p style={{ fontSize: 11, margin: 0, textAlign: "center", width: "100%" }}>{t("Spolu").toUpperCase()}</p>
                            </Row>
                            <Row>
                                <p style={{ margin: 0, textAlign: "center", width: "100%", fontSize: "20px" }}>
                                    {getPriceFromPaymentData()} {props.currency}
                                </p>
                            </Row>
                        </Col>

                        <PaymentRequests requests={paymentData.htmlFormRequests} />
                    </Row>
                </Card.Footer>

            </Card>
        </>
    );
}

const OrderStatusPage = (props) => {
    const isOrderSuccess = props.isOrderSuccess;
    const paymentData = props.orderResponse.paymentData;
    const orderException = props.orderException;

    const getOrderStatusPage = () => {
        if (!isOrderSuccess)
            return <OrderFailedScreen orderException={orderException} switchComponent={props.switchComponent} />;

        if (paymentData === null)
            return <OrderSuccessfulScreen
                switchComponent={props.switchComponent}
                orderResponse={props.orderResponse} />;

        return <ToOnlinePaymentScreen
            paymentData={paymentData}
            switchComponent={props.switchComponent}
            addNewFavourites={props.addNewFavourites}
            currency={props.currency}
        />;
    };

    return (
        <div className="order-status">
            {getOrderStatusPage()}
            {props.screenWidth < 1024 && <Footer deliveryRegionList={props.deliveryRegionList} />}
        </div>
    );
};

export default OrderStatusPage;
