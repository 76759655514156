import React from "react";
import OrderedAddon from "./OrderedAddon";
import { Row, Col, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import * as commonFunctions from "../../functions/commonFunctions";

const CartOverviewItem = (props) => {
    const { t } = useTranslation();

    const item = props.item;

    const addons = (!item.addons) ? false : item.addons.map((addon, i) => {
        const { id } = addon;
        return (
            <OrderedAddon key={id} addon={addon} />
        )
    });

    const iPlus = <FontAwesomeIcon icon={faPlus} />;

    return (
        <ListGroup.Item key={item.id} className="cart-item">
            <Col xs={12}>
                <Row>
                    <Col xs={2} className="cart-item-qty">{item.quantity}x</Col>
                    <Col xs={7} className="cart-item-name">{t(item.name)}</Col>
                    <Col xs={3} className="cart-item-price">{commonFunctions.getProductPrice(item, item.quantity)} {props.currency}</Col>
                </Row>
                {(item.addons.length > 0) &&
                    <Row>
                        <Col xs={2}>{iPlus}</Col>
                        <Col className="addons" xs={10}><ul>{addons}</ul></Col>
                    </Row>
                }
            </Col>
        </ListGroup.Item>
    );
};

export default CartOverviewItem;
