import React from "react";
import { Container, Jumbotron, Row, Col } from "react-bootstrap";
import { companyDetails } from "../../custom/data/dataCustom";
import { useTranslation } from "react-i18next";

const DeliveryRegions = ({ deliveryRegionList }) => {
    const { t } = useTranslation();
    let regions = deliveryRegionList && deliveryRegionList.map(reg => {
        return (
            <li key={reg}>{reg}</li>
        )
    })
    return (
        <>
            {(deliveryRegionList && deliveryRegionList.length > 0) &&
                <>
                    <h4>{t("Rozvoz")}:</h4>
                    <ul>
                        {regions}
                    </ul>
                </>
            }

        </>
    )
}

const Footer = ({ screenWidth, deliveryRegionList }) => {

    return (
        <Container
            fluid={(screenWidth > 1024) ? false : true}
            className="footer pt-3">
            <Jumbotron className="rounded-0 pb-3">
                <Row>
                    <Col xs={6} className="contact-info">
                        <h4>{("Kontakt")}:</h4>
                        <ul>
                            <li>{companyDetails.address}</li>
                            <li>{companyDetails.phone}</li>
                            <li>{companyDetails.email}</li>
                        </ul>
                    </Col>
                    <Col xs={6} className="delivery-info">
                        <DeliveryRegions deliveryRegionList={deliveryRegionList} />
                    </Col>
                </Row>
            </Jumbotron>

            <div className="powered-by pt-3">
                <a href="https://gastrofy.sk/">
                    Online objednávky pre reštauráce www.Gastrofy.sk
                </a>
            </div>
        </Container>
    );

}

export default Footer;
