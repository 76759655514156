import React from "react";
import { Button, Card } from "react-bootstrap";
import Footer from "../common/Footer";
import { useTranslation } from "react-i18next";
import OrderSuccessfulScreen from "./OrderSuccessfulScreen";

const PaymentFailedScreen = (props) => {
    const { t } = useTranslation();
    return (
        <Card className="order-status-fail">
            <Card.Header>{t("Online platba").toUpperCase()}</Card.Header>
            <Card.Body>
                <Card.Text className="status-title">
                    {t("Platba zlyhala, prosím zadajte objednávku znova alebo zopakujte platbu.").toUpperCase()} <br />
                    {t("Ďakujeme!").toUpperCase()}
                </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted">
                <a href={process.env.REACT_APP_HOME_PAGE}>
                    <Button variant="light" className="button-secondary" onClick={() => props.switchOnlinePayment(false) + props.switchComponent("productListScreen")}>
                        {t("Späť k objednávke").toUpperCase()}
                    </Button>
                </a>

            </Card.Footer>
        </Card>
    );
};

const OnlinePaymentStatusScreen = (props) => {
    const getPaymentStatus = () => {
        if (props.paymentSuccess) return <OrderSuccessfulScreen />;
        if (!props.paymentSuccess) return <PaymentFailedScreen switchOnlinePayment={props.switchOnlinePayment} />;
    }

    return (
        <div className="order-status">
            {getPaymentStatus()}
            {props.screenWidth < 1024 && <Footer />}
        </div>
    );
}

export default OnlinePaymentStatusScreen;
