import React, { useState, useEffect } from "react";
import AddonsList from "./AddonsList";
import VariationsList from "./VariationsList";
import OrderedAddon from "../cart/OrderedAddon"
import ProductAllergenList from "../common/ProductAlergensList";
import { Row, Col, ListGroup, Button, Modal, Card, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPepperHot, faLeaf, faRecycle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import * as commonFunctions from "../../functions/commonFunctions";

const SingleProduct = (props) => {
  let product = props.product.variationGroups.length < 1 ? props.product : JSON.parse(JSON.stringify(props.product));
  const { t } = useTranslation();

  const [productVariationGroups, setProductVariationGroups] = useState(product.variationGroups.length > 0 ? product.variationGroups : []);
  const [selectedProducVariations, setSelectedProductVariations] = useState();

  const [showAddons, setShowAddons] = useState(false);
  const [showVariations, setShowVariations] = useState(false);

  const [showModal, setShowModal] = useState()

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [orderedAddons, setOrderedAddons] = useState([]);
  const [modProductPrice, setModProductPrice] = useState(product.price);

  const [productOptionsException, setProductOptionsException] = useState();

  function updateProductVariation() {
    product.variationGroups = JSON.parse(JSON.stringify(selectedProducVariations));
    product.productsInfo.hasVariation = true;
    return true;
  }

  function undoSelectedVariation(groupIndex) {
    const variationGroups = JSON.parse(JSON.stringify(selectedProducVariations));;

    variationGroups[groupIndex].variations = [];
    setSelectedProductVariations(variationGroups);
  }

  function onVariationSelect(variation, groupIndex) {
    setProductOptionsException();
    const selected = JSON.parse(JSON.stringify(selectedProducVariations));

    selected[groupIndex].variations = [];
    selected[groupIndex].variations.push(variation);
    setSelectedProductVariations(selected);
  }

  function changeAddonQty(event, addon) {
    const updateAddons = orderedAddons;
    const previousPrice = modProductPrice;
    const index = updateAddons.findIndex(x => x.id === addon.id);
    if (event === "add") {
      if (index > -1) {
        updateAddons[index].quantity += 1;
      }
      else {
        addon.quantity = 1;
        updateAddons.push(addon)
      }
      setModProductPrice(previousPrice + addon.price);
    };

    if (event === "remove") {
      let addonQty = updateAddons[index].quantity;
      if (addonQty > 1) {
        updateAddons[index].quantity -= 1;
      }
      else {
        updateAddons.splice(index, 1);
      }
      setModProductPrice(previousPrice - addon.price);
    }
    setOrderedAddons(updateAddons);
  };

  function resetProductOptions() {
    setProductOptionsException();
    setOrderedAddons([]);
    setModProductPrice(product.price);

    const variationGroups = JSON.parse(JSON.stringify(product.variationGroups))
    variationGroups.forEach(group => {
      group.variations = [];
    });
    setSelectedProductVariations(variationGroups);
  }

  function isAllVariationsSelected() {
    let selected = true;
    for (let v of selectedProducVariations) {
      selected = v.variations && v.variations.length > 0;
      if (!selected) break;
    }
    return selected;
  }

  function saveProductOptions() {
    if (product.variationGroups.length > 0) updateProductVariation();

    if (showVariations && !isAllVariationsSelected()) {
      setProductOptionsException("Zadajte prosím všetky možnosti");
      return;
    }

    if (showVariations) {
      if (product.productsInfo.hasAddons && !product.tempId) {
        setShowVariations(false);
        setShowAddons(true);
      }
      else {
        setShowVariations(false);
        props.changeProductQty("add", product, orderedAddons);
        handleClose()
      }
    }
    else {
      props.changeProductQty("add", product, orderedAddons);
      handleClose()
    }

  }

  function handleOnProductClick(product) {
    resetProductOptions();
    if (product.variationGroups.length > 0) {
      setShowAddons(false);
      setShowVariations(true);
      handleShow(true);
    }
    else if (!product.tempId && product.productsInfo.hasAddons) {
      setShowVariations(false);
      setShowAddons(true);
      handleShow(true);
    }
    else props.changeProductQty("add", product);
  }

  useEffect(() => {
    function setInitialProductVariations(initiialData) {
      const initialVariationGroups = initiialData;

      initialVariationGroups.forEach(group => {
        group.variations = [];
      });

      setSelectedProductVariations(initialVariationGroups);
    }

    if (!selectedProducVariations && product.variationGroups.length > 0) {
      setProductVariationGroups(product.variationGroups);
      setInitialProductVariations(JSON.parse(JSON.stringify(product.variationGroups)))
    }

  }, [productVariationGroups, product, selectedProducVariations]);

  const addons = product.addons && product.addons.map((addon, i) => {
    const { id } = addon;
    return (
      <OrderedAddon key={id} addon={addon} />
    )
  });
  const iPlus = <FontAwesomeIcon icon={faPlus} />;

  return (
    <>
      <ListGroup.Item
        data-spy="scroll"
        data-target="#categories-bar"
        data-offset="0"
        className="product-cart"
        variant="light"
        key={product.id}
        onClick={() =>
          handleOnProductClick(product)}
      >
        <Row>
          <Col xs={9} style={{ paddingLeft: "0" }}>
            <Row className="product-title">
              {t(product.name)}
              <Col xs="auto">
                {(product.tags && product.tags.indexOf("pikant") > -1) &&
                  <FontAwesomeIcon className="mr-3" icon={faPepperHot} color="red" />
                }
                {(product.tags && product.tags.indexOf("vegán") > -1) &&
                  <FontAwesomeIcon className="mr-3" icon={faLeaf} color="green" />
                }
                {(product.tags && product.tags.indexOf("záloha") > -1) &&
                  <FontAwesomeIcon className="mr-3" icon={faRecycle} color="gray" />
                }
              </Col>
            </Row>
            <Row className="product-description">
              {t(product.description)}
            </Row>
          </Col>
          <Col xs={3} style={{ padding: 0 }} >
            <p className="product-price">
              {commonFunctions.getProductPrice(product)} {props.currency}
            </p>
          </Col>
        </Row>
        <Row style={{ paddingTop: "5px" }}>
          <Col xs={4} style={{ backgroundColor: "inherit", paddingLeft: "0" }}>
            {product.imageUrl !== null &&
              <img style={{ width: "70px", height: "70px" }} src={product.imageUrl} alt="" className="mt-1" loading="lazy" />}
          </Col>
          <Col xs={4}>
          </Col>
          <Col xs={4} className="product-alergens">
            <ProductAllergenList allergens={product.allergens} />
          </Col>
        </Row>

        {product.addons.length > 0 &&
          <>
            <hr />
            <Row>
              <Col xs={1}>{iPlus}</Col>
              <Col className="addons" xs={10}> <ul>{addons}</ul></Col>
            </Row>
          </>}

      </ListGroup.Item >

      <Modal
        show={showModal}
        onHide={handleClose}
        animation={false}
        backdrop="static"
        keyboard={false}
        className="addons-list"
      >
        <div className="product-view">
          <Row>
            <Col xs={9} style={{ paddingLeft: "0" }}>
              <Row className="product-title">
                {t(product.name)}
              </Row>
              <Row className="product-description">
                {t(product.description)}
              </Row>
            </Col>
            <Col xs={3} style={{ padding: 0 }} >
              <p className="product-price">
                {commonFunctions.getProductPrice(product)} {props.currency}
              </p>
            </Col>
          </Row>
          <Row style={{ paddingTop: "5px" }}>
            <Col xs={3} style={{ backgroundColor: "inherit", paddingLeft: "0" }}>
              {product.imageUrl !== null &&
                <img style={{ width: "70px", height: "70px" }} src={product.imageUrl} alt="" className="mt-1" loading="lazy" />}
            </Col>
            <Col xs={5}>
            </Col>
            <Col xs={4} className="product-alergens">
              <ProductAllergenList allergens={product.allergens} />
            </Col>
          </Row>
        </div>
        <Card className="cart rounded-0">
          {showAddons && <Card.Header className="rounded-0">{t("Pridať prílohy").toUpperCase()}</Card.Header>}
          {productOptionsException && <Card.Header style={{ color: "red" }} className="rounded-0">{t(productOptionsException)}</Card.Header>}
        </Card>
        <Modal.Body className="addons-list-body">

          {showAddons &&
            <ListGroup variant="flush">
              <AddonsList
                productsInfo={product.productsInfo}
                changeAddonQty={changeAddonQty}
                currency={props.currency}
              />
            </ListGroup>
          }

          {showVariations &&
            <ButtonGroup toggle>
              <VariationsList
                productVariationGroups={productVariationGroups}
                product={product}
                selectedProducVariations={selectedProducVariations}
                onVariationSelect={onVariationSelect}
                undoSelectedVariation={undoSelectedVariation}
                currency={props.currency}
              />
            </ButtonGroup>
          }

        </Modal.Body>
        <Modal.Footer className="addons-list-footer">
          <Col xs={4}>
            <Button className="button-secondary" variant="light" onClick={handleClose}>
              {t("Zrušiť").toUpperCase()}
            </Button>
          </Col>
          <Col>
            <Row>
              <p style={{ fontSize: 11, margin: 0, textAlign: "center", width: "100%" }}>{t("Cena s prílohami").toUpperCase()}</p>
            </Row>
            <Row>
              <p style={{ margin: 0, textAlign: "center", width: "100%" }}>
                {modProductPrice.toFixed(2)} {props.currency}
              </p>
            </Row>
          </Col>
          <Col xs={4}>
            <Button className="button-primary" variant="light"
              onClick={() => saveProductOptions()}
            >{t("Pridať").toUpperCase()}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SingleProduct;
