import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { alergens } from "../../data/Data";

const SingleAlergen = (props) => {
    const { t } = useTranslation();

    return (
        <li>
            <Row>
                <Col xs={2} className="alergen-id">{props.alergen.id}:</Col>
                <Col xs={9} className="alergen-value">{t(props.alergen.value)}</Col>
            </Row>
        </li>
    )
}

const AlergenList = () => {
    let alergenList = alergens.map((alergen, i) => {
        const { id } = alergen;
        return (
            <SingleAlergen
                alergen={alergen}
                key={id}
            />
        );
    });
    return (
        <ul>
            {alergenList}
        </ul>
    )
}

export default AlergenList;