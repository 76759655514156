import React, { useState } from "react";
import { Form, Col, Card, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import AddressAutosuggest from "../contactForm/AddressAutosuggest";
import { useTranslation } from "react-i18next";

const ContactForm = (props) => {
    const { t } = useTranslation();
    const localFormData = props.localFormData;

    const [firstName, setFirstName] = useState(localFormData.firstName);
    const [lastName, setLastName] = useState(localFormData.lastName);
    const [phone, setPhone] = useState(localFormData.phone);
    const [email, setEmail] = useState(localFormData.email);
    const [note, setNote] = useState(localFormData.note);

    function saveInputValues(input, inputName) {
        const inputValue = input.target.value;

        switch (inputName) {
            case "firstName":
                setFirstName(inputValue);
                props.setLocalStorageItem("firstName", inputValue);
                break;
            case "lastName":
                setLastName(inputValue);
                props.setLocalStorageItem("lastName", inputValue);
                break;
            case "phone":
                setPhone(inputValue);
                props.setLocalStorageItem("phone", inputValue);
                break;
            case "email":
                setEmail(inputValue);
                props.setLocalStorageItem("email", inputValue);
                break;
            case "note":
                setNote(inputValue);
                props.setLocalStorageItem("note", inputValue);
                break;
            default: return null;
        }
        props.getLocalFormData();
    };

    const iExclametion = <FontAwesomeIcon icon={faExclamationCircle} />;

    return (
        <Card className="contact-form rounded-0">
            <Card.Header className="rounded-0">{t("Kontaktné údaje").toUpperCase()}</Card.Header>

            {props.infoPanel}

            {(props.formException) && <Alert variant="danger">{iExclametion} {" "} {t(props.formException)}</Alert>}

            <Form>
                <div className="form-data">

                    <Form.Row>
                        <Form.Group as={Col} controlId="firstName">
                            <Form.Label >{t("Meno").toUpperCase()}</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                onChange={e => saveInputValues(e, "firstName")} value={firstName} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="lastName">
                            <Form.Label>{t("Priezvisko").toUpperCase()}</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                onChange={e => saveInputValues(e, "lastName")} value={lastName} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="phone">
                            <Form.Label>{t("Telefón").toUpperCase()}</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                onChange={e => saveInputValues(e, "phone")} value={phone} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="email">
                            <Form.Label>{t("Email").toUpperCase()}</Form.Label>
                            <Form.Control
                                size="sm"
                                type="email"
                                onChange={e => saveInputValues(e, "email")}
                                value={email} />
                        </Form.Group>
                    </Form.Row>

                    <AddressAutosuggest
                        localFormData={localFormData}
                        getLocalFormData={props.getLocalFormData}
                        selectedDeliveryType={props.selectedDeliveryType}
                        setAddressValues={props.setAddressValues}
                        selectedAddress={props.selectedAddress}
                        clearAddressValues={props.clearAddressValues}
                        translations={t}
                    />

                    <Form.Group controlId="note">
                        <Form.Label>{t("Správa pre reštauráciu").toUpperCase()}</Form.Label>
                        <Form.Control as="textarea" rows="3" onChange={e => saveInputValues(e, "note")} value={note}
                            placeholder={t("sem možete zadať špeciálne požiadavky k objednávke alebo detail o adrese...")} />
                    </Form.Group>

                    <p className="mt-2" style={{ fontSize: "12px" }}>
                        {t("Zaslaním objednávky súhlasíte so spracovaním osobných údajov")}
                    </p>
                </div>
            </Form>
        </Card>
    )
}

export default ContactForm;
