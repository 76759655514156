import React, { useEffect, useState } from "react";
import * as screenList from "../../data/appScreenList";
import { ListGroup, Col, Button } from "react-bootstrap"
import Footer from "../common/Footer";
import { useTranslation } from "react-i18next";

const SingleDay = (props) => {
    const { t } = useTranslation();
    const dayEnum = props.dayEnum;
    const getOpeningHoursFormat = props.getOpeningHoursFormat;
    return (
        <ListGroup horizontal>
            <ListGroup.Item className="rounded-0">{t(dayEnum(props.dayOfWeek)) + ": "}</ListGroup.Item>
            <ListGroup.Item className="rounded-0">
                {getOpeningHoursFormat(props.openingAt, props.closingAt)}
            </ListGroup.Item>
        </ListGroup>
    );
};

const ClosedShop = (props) => {
    const { t } = useTranslation();
    const [openingHours, setOpeningHours] = useState();
    const beginValid = props.validateCsharpDate(props.outOfOrder.beginningAt);
    const endValid = props.validateCsharpDate(props.outOfOrder.endingAt);

    useEffect(() => {
        const handleDaysOff = () => {
            const hours = props.openingHours;
            const weekDays = [0, 1, 2, 3, 4, 5, 6];
            weekDays.forEach(day => {
                const dayIndex = hours.findIndex(x => x.dayOfWeek === day);
                (dayIndex === -1) && hours.push({ dayOfWeek: day, openingAt: "N/A", closingAt: "N/A" });
            });

            hours.sort((a, b) => (a.dayOfWeek > b.dayOfWeek) ? 1 : -1);
            hours.push(hours.shift());

            setOpeningHours(hours);
        }

        handleDaysOff();

    }, [props.openingHours]);

    function dayEnum(day) {
        switch (day) {
            case 0: return "Nedeľa";
            case 1: return "Pondelok";
            case 2: return "Utorok";
            case 3: return "Streda";
            case 4: return "Štvrtok";
            case 5: return "Piatok";
            case 6: return "Sobota";
            default: return null;
        }
    };

    function getOpeningHoursFormat(opening, closing) {
        if (opening === "N/A" || closing === "N/A") {
            return "zatvorené"
        }
        const padO = opening.toString().padStart(4, "0");
        const padC = closing.toString().padStart(4, "0");
        const open = padO.slice(0, 2) + ":" + String(padO).slice(2);
        const close = padC.slice(0, 2) + ":" + String(padC).slice(2);
        return open + " - " + close;
    }

    function getDateToString(date) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return day + "." + month + "." + year;
    };

    let days = openingHours && openingHours.map((day, i) => {
        const { dayOfWeek, openingAt, closingAt } = day;
        return (
            <SingleDay
                key={dayOfWeek}
                dayOfWeek={dayOfWeek}
                openingAt={openingAt}
                closingAt={closingAt}
                dayEnum={dayEnum}
                getOpeningHoursFormat={getOpeningHoursFormat} />
        );
    });

    return (
        <>
            {props.infoPanel}

            {props.preOrderAllowed &&
                <>
                    <Button className="button-preorder" variant="light" onClick={() => props.switchComponent(screenList.PRODUCT_LIST)}>{t("Predobjednať")}</Button>
                    <p className="preOrder-info">Objednávka bude doručená prvým rozvozom</p>
                </>
            }

            {(!props.outOfOrderCurrently) ?
                (<Col sm={6} className="openingTimes mt-5">
                    <ListGroup.Item className="openingTimes-header"
                        style={{ width: "100%" }}>{t("Otváracie hodiny")}
                    </ListGroup.Item>
                    {days}
                </Col>) :
                (<Col sm={6} className="openingTimes mt-5">
                    <ListGroup.Item className="openingTimes-header" style={{ width: "100%" }}>{t("Zatvorené")}</ListGroup.Item>
                    <ListGroup horizontal>
                        <ListGroup.Item className="rounded-0">{t("od")}</ListGroup.Item>
                        <ListGroup.Item className="rounded-0">{getDateToString(beginValid)}</ListGroup.Item>
                    </ListGroup>
                    <ListGroup horizontal>
                        <ListGroup.Item className="rounded-0">{t("do")}</ListGroup.Item>
                        <ListGroup.Item className="rounded-0">{getDateToString(endValid)}</ListGroup.Item>
                    </ListGroup>
                </Col>)}

            {props.screenWidth < 1024 && <Footer deliveryRegionList={props.deliveryRegionList} />}
        </>
    )
}

export default ClosedShop;



