import React from "react";
import { Col, Row, Card, ToggleButton } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDotCircle } from "@fortawesome/fontawesome-free-regular";
import { faCheck, faUndoAlt } from "@fortawesome/free-solid-svg-icons"

function createVariation(name) {
    return { name }
}

const VariationsList = ({ product, selectedProducVariations, onVariationSelect, undoSelectedVariation }) => {
    const productVariationGroups = product.variationGroups;

    const iCheckCircle = <FontAwesomeIcon icon={faDotCircle} />;
    const iCircle = <FontAwesomeIcon icon={faCircle} />;
    const iCheck = <FontAwesomeIcon icon={faCheck} color="green" />;
    const iUndo = <FontAwesomeIcon icon={faUndoAlt} color="dodgerblue" />;

    function isGroupSelected(groupIndex) {
        return selectedProducVariations[groupIndex].variations.length > 0;
    }

    return (
        <Card className="product-variations-container rounded-0">
            {productVariationGroups.map((group, groupIndex) => {
                return (
                    <div key={group.name}>
                        <Card.Title onClick={() => undoSelectedVariation(groupIndex)} className="product-variation-title mt-3">
                            <Row>
                                <Col xs={2}>{isGroupSelected(groupIndex) && iCheck}</Col>
                                <Col>
                                    {!isGroupSelected(groupIndex) ? group.name : `${group.name}: ${selectedProducVariations[groupIndex].variations[0].name}`}
                                </Col>
                                <Col xs={2}>{isGroupSelected(groupIndex) && iUndo}</Col>
                            </Row>
                        </Card.Title>
                        {group.variations.map((variation) => (
                            !isGroupSelected(groupIndex) &&
                            <ToggleButton
                                className="variation-radio float-left mb-2"
                                variant="secondary"
                                key={variation.name}
                                type="radio"
                                name="variation"
                                disabled={false}
                                value={variation.name}
                                checked={selectedProducVariations[groupIndex].variations.length > 0 && selectedProducVariations[groupIndex].variations[0].name === variation.name}
                                onChange={e => onVariationSelect(createVariation(e.currentTarget.value), groupIndex)}
                            >
                                <Row>
                                    <Col xs={1}>{(selectedProducVariations[groupIndex].variations.length > 0 && selectedProducVariations[groupIndex].variations[0].name === variation.name) ? iCheckCircle : iCircle}</Col>
                                    <Col xs={9}>{variation.name}</Col>
                                </Row>
                            </ToggleButton>
                        )
                        )}
                    </div>
                )
            })


            }
        </Card>
    )
}



export default VariationsList;