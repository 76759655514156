
export const getProductPrice = (product, qty = 1, toFixed = true) => {
    let productPrice = product.price;
  
    for(let addon of product.addons) {
        productPrice += (addon.price * addon.quantity);
    }
  
    return toFixed ? (qty * productPrice).toFixed(2) : qty * productPrice;
  } 
