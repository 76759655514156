import React from "react";

const SingleProductAllergen = (props) => {
    return <li>{props.id}</li>
};

const ProductAllergenList = (props) => {
    const arr = (props.allergens === null || props.allergens === "") ? [] : props.allergens.split(",");
    const allergens = [];

    arr.forEach(el => {
        allergens.push({ id: el });
    });

    const alergenList = allergens.map(alg => {
        const { id } = alg
        return <SingleProductAllergen id={id} key={id} />
    })
    return <ul>
        {alergenList}
    </ul>
}

export default ProductAllergenList;





