import React from "react";
import CartScreen from "./CartScreen";
import CategoryScrollSpyNavbar from "../productList/CategoryScrollSpyNavbar";
import CategorySections from "../productList/CategorySections";
import { Row, Col } from "react-bootstrap";

const ProductListScreen = (props) => {
  const screenWidth = props.screenWidth;
  const categories = props.categories;
  const categoryNames = categories.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.name }), {});

  return (
    <>
      <div className="categories-navbar">
        <CategoryScrollSpyNavbar categoryNames={categoryNames} />
      </ div>

      {props.infoPanel}

      {screenWidth < 1024 ? (
        <CategorySections
          categoryNames={categoryNames}
          categories={categories}
          changeProductQty={props.changeProductQty}
          currency={props.currency}
        />
      ) : (
        <Row>
          <Col>
            <CategorySections
              categoryNames={categoryNames}
              categories={categories}
              changeProductQty={props.changeProductQty}
              currency={props.currency}
            />
          </Col>
          <Col>
            <CartScreen
              screenWidth={screenWidth}
              inCart={props.inCart}
              price={props.price}
              currency={props.currency}
              changeProductQty={props.changeProductQty}
              selectedDeliveryType={props.selectedDeliveryType}
              selectedPaymentType={props.selectedPaymentType}
              switchComponent={props.switchComponent}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ProductListScreen;
