import React, { Component } from "react";
import { Button, Container, Row, Col, Spinner, Alert } from "react-bootstrap";
import * as screenList from "../src/data/appScreenList";
import Header from "./components/common/Header";
import ProductListScreen from "./components/screens/ProductListScreen";
import OrderSummaryScreen from "./components/screens/OrderSummaryScreen";
import CartScreen from "./components/screens/CartScreen";
import ContactFormScreen from "./components/screens/ContactFormScreen";
import Footer from "./components/common/Footer";
import ClosedShop from "./components/screens/ClosedShopScreen";
import OrderStatusScreen from "./components/screens/OrderStatusScreen";
import OnlinePaymentStatusScreen from "./components/screens/OnlinePaymentStatusScrenn";
import FeedbackScreen from "./components/screens/FeedbackScreen";
import InfoPanel from "./components/common/InfoPanel";
import { exceptionMessages } from "./data/Data";
import * as customData from "./custom/data/dataCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faChevronRight, faChevronLeft, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { pushCartChangeToGtm, pushOrderToGtm } from "./functions/googleDataLayerFunctions";
import { exception } from "react-ga";
import * as commonFunctions from "./functions/commonFunctions";

class Gastrofy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      menuProductIds: [],
      price: 0,
      onlinePayment: false,
      currency: customData.currency.display,
      favouriteItems: { name: "Obľúbené", products: [], addons: [] },
      selectedAddress: { id: 0, street: "", city: "", postCode: "", isShipping: false },
      processing: false,
      connectionFailure: false
    };
  }

  createCategory = (name, products = [], addons = []) => {
    return { name, products, addons }
  }

  getData = () => {
    fetch(`${process.env.REACT_APP_BACKEND}/shop`)
      .then(res => res.json())
      .then(data => {
        // console.log(data);
        let categories = data.categories;
        const actualMenuCategory = this.getActualMenuProducts(data.dailyMenus);

        if (actualMenuCategory) {
          categories.unshift(actualMenuCategory);
        }

        this.setState({
          categories: categories,
          payments: data.payments,
          deliveries: [],
          openingHours: data.openingHours,
          outOfOrder: data.outOfOrder,
          averageDeliveryTimeInMinutes: data.averageDeliveryTimeInMinutes,
          selectedDeliveryType: { price: 0 },
          selectedPaymentType: data.payments[0],
          shippingPrices: data.shippingPrices,
        });
        this.setPaymentType(data.payments[0].id)
        this.isShopOpend(data.openingHours, data.outOfOrder);
        this.getDeliveryRegions(data.shippingPrices);
        this.updateLocalData(data.categories);
      }).catch(err => {
        console.log("ERROR: " + err);
      })
  };

  getActualMenuProducts = (menus) => {
    if (menus.length < 1) return;
    else {
      const currentDateTime = new Date();
      // currentDateTime.setHours(13, 0, 0);
      const time = currentDateTime.getHours() * 100 + currentDateTime.getMinutes();

      const actualMenus = menus.filter(menu => menu.endingAt > time);

      const menuProductIds = [];
      const menuProducts = [];

      actualMenus.forEach(menu => {
        for (let cat of menu.categories) {
          cat.products.forEach(product => {
            menuProducts.push(product);
            menuProductIds.push(product.id);
          })
        }
      })

      this.setState({ menuProductIds });

      if (actualMenus.length < 1 || menuProducts.length < 1) return null;
      else {
        const fromTo = this.getMenuTimePeriod(actualMenus[0].startingAt, actualMenus[0].endingAt);
        return this.createCategory(`Dnešné menu (${fromTo})`, menuProducts);
      }
    }
  }

  getMenuTimePeriod(opening, closing) {
    const padO = opening.toString().padStart(4, "0");
    const padC = closing.toString().padStart(4, "0");
    const open = padO.slice(0, 2) + ":" + String(padO).slice(2);
    const close = padC.slice(0, 2) + ":" + String(padC).slice(2);
    return open + " - " + close;
  };

  getDeliveryRegions = (shippingData) => {
    const deliveryRegionList = [];
    for (let data of shippingData) {
      const priceRegions = data.name.split(", ");
      priceRegions.forEach(reg => {
        deliveryRegionList.push(reg);
      })
    }

    this.setState({ deliveryRegionList });
  };

  setAddressValues = (addressId) => {
    const req = new Request(`${process.env.REACT_APP_BACKEND}/search/${addressId}`, {
      method: "GET",
      cache: "default"
    });
    fetch(req)
      .then(response => {
        if (response.status === 200)
          return response.json();

        throw new exception(`HTTP ${response.status}`);
      }
      )
      .then(data => {
        const address = data.address;
        const deliveries = data.deliveries;

        address.isShipping = 0 <= deliveries.findIndex(x => x.id === "Currier");

        this.addIsDeliveryAllowed(this.state.price, deliveries);
        this.setDeliveryType(
          address.isShipping ? "Currier" : "Pickup",
          deliveries
        );

        this.setState({
          selectedAddress: address, deliveries
        });

        this.setLocalStorageItem("addressId", address.id);
      })
      .catch(err => console.error(err))
  };

  setLocalStorageItem = (name, value) => {
    if (!name)
      return;

    if (!value)
      value = null;

    try {
      localStorage.setItem(name, JSON.stringify({ version: 1, value }));
    }
    catch (e) {
      console.warn(e);
    }
  };

  getLocalStorageItem = (name) => {
    let value;
    try {
      const data = JSON.parse(localStorage.getItem(name));

      if (data?.version === 1)
        value = data.value;
      else
        localStorage.removeItem(name);
    } catch (e) {
      console.warn(e);
      try { localStorage.removeItem(name); }
      catch { }
    }

    return value === 'null' || value === 'undefined' || !value ? null : value;
  }

  clearAddressValues = () => {
    this.setState({ selectedAddress: {} });
  };

  onlinePaymentResponse = (pay) => {
    const params = new URLSearchParams(window.location.search);
    const payment = params.get('payment');

    if (!payment) {
      this.setState({ onlinePayment: false }); return false;
    }
    const paymentSuccess = payment === 'success';
    if (paymentSuccess) {
      this.setState({ paymentSuccess: true });
      this.switchComponent(screenList.PAYMENT_STATUS);
    }
    else {
      this.setState({ paymentSuccess: false });
      this.switchComponent(screenList.PAYMENT_STATUS);
    }

    this.setState({ onlinePayment: true });
    return true;
  }

  componentDidMount() {
    this.loadApplyDiscount();
    this.onlinePaymentResponse();
    this.getLocalFormData();

    if (process.env.REACT_APP_SPLIT_PAY_ON_DELIVERY)
      this.setState({ selectedDeliveryOption: customData.payOnDeliveryOptions[0] });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.dimensions.width >= 1024 && this.state.renderedComponent === "contactFormScreen")
      this.switchComponent(screenList.ORDER_SUMMARY);

    if (this.props.dimensions.width >= 1024 && this.state.renderedComponent === screenList.CART)
      this.switchComponent(screenList.PRODUCT_LIST);

  };

  updateLocalData(categories) {
    let allCategories = categories;
    let originalCart = this.getOriginalCart();
    let favouriteItems = this.getLocalStorageItem("favouriteItems");
    if ((originalCart.products && originalCart.products.length > 0) || favouriteItems) {
      let productsUpToDate = [];
      let addonsUpToDate = [];
      categories.forEach(cat => {
        if (cat.name !== "Obľúbené")
          cat.products.forEach(prod => {
            productsUpToDate.push(prod);
          });
        cat.addons.forEach(add =>
          addonsUpToDate.push(add));
      });

      if (originalCart.products && originalCart.products.length > 0) {
        originalCart.products = this.updateCart(productsUpToDate, addonsUpToDate, originalCart.products);
      }
      if (favouriteItems) {
        favouriteItems.products = this.updateCart(productsUpToDate, addonsUpToDate, favouriteItems.products);

        let favProducts = favouriteItems.products;
        favProducts.sort((a, b) => (a.quantity < b.quantity) ? 1 : ((b.quantity < a.quantity) ? -1 : 0));
        for (let i = favProducts.length; i > 4; i--) favProducts.pop();
        allCategories.splice(0, 0, favouriteItems);
        allCategories[0].products = favProducts;
        this.setLocalStorageItem("favouriteItems", favouriteItems);
      }
    };

    this.setState({ categories: allCategories, favouriteItems });
    this.setOriginalCart(originalCart);
  };

  updateCart(prodUp, addUp, cart) {
    const productsUpToDate = prodUp;
    const addonsUpToDate = addUp;
    const cartToUpdate = cart;

    cartToUpdate.forEach(product => {
      let itemUpToDate = productsUpToDate.findIndex(x => x.id === product.id);
      let cartItem = cartToUpdate.findIndex(x => x.id === product.id);
      if (itemUpToDate < 0) cartToUpdate.splice(cartItem, 1);
      else {
        cartToUpdate[cartItem].name = productsUpToDate[itemUpToDate].name;
        cartToUpdate[cartItem].price = productsUpToDate[itemUpToDate].price;
        cartToUpdate[cartItem].description = productsUpToDate[itemUpToDate].description;
        cartToUpdate[cartItem].allergens = productsUpToDate[itemUpToDate].allergens;
        cartToUpdate[cartItem].imageUrl = productsUpToDate[itemUpToDate].imageUrl;
      };
      if (product.addons) {
        product.addons.forEach(addon => {
          let addonUpToDate = addonsUpToDate.findIndex(x => x.id === addon.id);
          let cartAddon = product.addons.findIndex(x => x.id === addon.id);
          if (addonUpToDate < 0) cartToUpdate[cartItem].addons.splice(cartAddon, 1);
          else {
            cartToUpdate[cartItem].addons[cartAddon].name = addonsUpToDate[addonUpToDate].name;
            cartToUpdate[cartItem].addons[cartAddon].price = addonsUpToDate[addonUpToDate].price;
          };
        })
      };
    });
    return cartToUpdate
  };

  updatePrice = (cart) => {

    const deliveryPrice = cart.delivery?.price ?? 0;
    const paymentPrice = cart.payment?.price ?? 0;

    const services = deliveryPrice + paymentPrice;
    const products = cart.products ?? [];

    let productsPrice = 0;
    products.forEach(product => {
      productsPrice += commonFunctions.getProductPrice(product, product.quantity, false);
    });

    const price = Math.round(((productsPrice + services) + Number.EPSILON) * 100) / 100;
    const deliveries = this.addIsDeliveryAllowed(productsPrice, this.state.deliveries ?? []);

    this.setState({ price, deliveries });

    return price;
  };

  validateCsharpDate(date) {
    return new Date
      (
        date.slice(0, 4),
        parseInt(date.slice(5, 7)) - 1,
        date.slice(8, 10),
        date.slice(11, 13),
        date.slice(14, 16)
      );
  };

  addIsDeliveryAllowed = (priceForProducts, deliveries) => {
    deliveries.forEach(delivery => {
      delivery.isDeliveryAllowed = delivery.minOrderPrice <= priceForProducts;
    });
    return deliveries;
  }

  getDelivery = () => this.state.cart?.delivery ?? { price: 0 };

  setDeliveryType = (id, recievedDeliveries) => {
    const deliveries = recievedDeliveries ?? this.state.deliveries;
    let index = deliveries.findIndex(x => x.id === id && x.isDeliveryAllowed);

    if (index < 0)
      index = deliveries.findIndex(x => x.isDeliveryAllowed);

    const selectedDelivery = index < 0 ? { price: 0 } : deliveries[index];

    this.setOriginalCart({ delivery: selectedDelivery });
  };

  getPayment = () => this.state.cart?.payment ?? { price: 0 };

  setPaymentType = (id) => {
    const payments = this.state.payments;
    const index = payments.findIndex(x => x.id === id);

    this.setOriginalCart({ payment: (index < 0 ? { price: 0 } : payments[index]) });
  };

  setDeliveryOption = (name) => {
    this.setState({ selectedDeliveryOption: name });
  }

  isShopOpend = (hours, outOfOrder) => {
    const currentDateTime = new Date();
    const currentDayOfWeek = currentDateTime.getDay();

    const getCurrentOpeningTimes = hours.find(day => {
      return day.dayOfWeek === currentDayOfWeek;
    });

    let isOpened;
    let outOfOrderCurrently;
    let preOrderAllowed = false;

    if (!getCurrentOpeningTimes) {
      isOpened = false;
    }
    else {
      const time = currentDateTime.getHours() * 100 + currentDateTime.getMinutes();
      const openingToday = getCurrentOpeningTimes.openingAt;
      const closingToday = getCurrentOpeningTimes.closingAt;

      isOpened = openingToday < time && time < closingToday;
      preOrderAllowed = !!process.env.REACT_APP_PREORDER_ALLOWED && time < closingToday;
    }

    if (outOfOrder) {
      const beginValid = this.validateCsharpDate(outOfOrder.beginningAt);
      const endValid = this.validateCsharpDate(outOfOrder.endingAt);

      outOfOrderCurrently = (currentDateTime > beginValid) && (currentDateTime < endValid);
      if (outOfOrderCurrently) isOpened = false;
    }

    this.setState({
      outOfOrderCurrently,
      isOpened,
      preOrderAllowed,
      renderedComponent: isOpened ? screenList.PRODUCT_LIST : screenList.CLOSED_SHOP
    });

    return isOpened ? isOpened : preOrderAllowed;
  };

  applyDiscounts = (originalCart) => {
    const cart = JSON.parse(JSON.stringify(originalCart));
    return window.ApplyDiscounts(cart);
  }

  loadApplyDiscount = () => {
    const script = document.createElement("script");
    script.src = (`${process.env.REACT_APP_BACKEND}/shop.js`);
    script.async = true;
    script.onload = () => { this.getData(); };
    document.body.appendChild(script);
  };

  getOriginalCart = () => {
    let originalCart = { ...this.state.originalCart };

    if (!originalCart)
      originalCart = this.getLocalStorageItem('Cart');

    return originalCart ?? {};
  };

  setOriginalCart = (value, replace) => {
    const originalCart = replace ? value : Object.assign(this.getOriginalCart(), value);
    const cart = this.applyDiscounts(originalCart);
    this.setLocalStorageItem('Cart', originalCart);
    this.setState({ cart, originalCart });

    this.updatePrice(cart);
  };

  restoreCategoryName = (product) => {
    const restoredProduct = { ...product };
    const ordiginalCategories = this.state.categories.filter(cat => cat.name !== "Obľúbené");

    for (let category of ordiginalCategories) {
      let productIndex = category.products.findIndex(prd => prd.id === restoredProduct.id);

      if (productIndex > -1) {
        restoredProduct.categoryName = category.name;
        return restoredProduct;
      }
    }

    return product;
  }

  changeProductQty = (event, product, addons = []) => {
    const hasNewAddons = addons.length > 0;
    const updateInCart = this.getOriginalCart().products ?? [];

    if (product.categoryName === "Obľúbené")
      product = this.restoreCategoryName(product);

    if (event === "add") {
      let type;
      if (!hasNewAddons && product.tempId && product.addons.length > 0) type = "getCustomized";
      if (!hasNewAddons && !product.tempId && product.addons.length < 1) type = "getRegular";
      if ((hasNewAddons && !product.tempId && product.addons.length < 1) || product.productsInfo.hasVariation) type = "getNewCustomized";

      let index;
      switch (type) {
        case "getRegular":
          index = updateInCart.findIndex(x => x.id === product.id);
          const currentP = updateInCart[index];
          if (index > -1 && currentP.addons.length < 1) {
            updateInCart[index].quantity++;
            pushCartChangeToGtm(product, customData.currency.name, "addedToCart");
          }
          else {
            product.quantity = 1;
            updateInCart.push(product);
            pushCartChangeToGtm(product, customData.currency.name, "addedToCart");
          }
          break;

        case "getCustomized":
          index = updateInCart.findIndex(x => x.tempId === product.tempId);
          if (index > -1) {
            updateInCart[index].quantity += 1;
            pushCartChangeToGtm(product, customData.currency.name, "addedToCart");
          }
          else {
            let clonedProduct = JSON.parse(JSON.stringify(product));
            clonedProduct.quantity = 1;
            clonedProduct.addons = product.addons;
            updateInCart.push(clonedProduct);
            pushCartChangeToGtm(product, customData.currency.name, "addedToCart");
          }
          break;

        case "getNewCustomized":
          let reservedTempIds = [];
          (this.state.favouriteItems) &&
            this.state.favouriteItems.products.forEach(p => { (p.tempId) && reservedTempIds.push(p.tempId) });

          updateInCart.forEach(p => { (p.tempId) && reservedTempIds.push(p.tempId) });
          reservedTempIds.sort((a, b) => (a < b) ? 1 : ((b < a) ? -1 : 0));

          let clonedProduct = JSON.parse(JSON.stringify(product))
          clonedProduct.quantity = 1;
          clonedProduct.addons = addons;
          clonedProduct.tempId = (reservedTempIds.length === 0) ? 1 : reservedTempIds[0] + 1;
          updateInCart.push(clonedProduct);
          pushCartChangeToGtm(clonedProduct, customData.currency.name, "addedToCart");
          break;
        default: return null;
      }
    }

    if (event === "increase") {
      let index = (!product.tempId) ?
        updateInCart.findIndex(x => x.id === product.id && !x.tempId) :
        updateInCart.findIndex(x => x.tempId === product.tempId);
      updateInCart[index].quantity += 1;
      pushCartChangeToGtm(product, customData.currency.name, "cartItemIncreased");
    }

    if (event === "remove") {
      let index = (!product.tempId) ?
        updateInCart.findIndex(x => x.id === product.id && !x.tempId) :
        updateInCart.findIndex(x => x.tempId === product.tempId);
      let itemQty = updateInCart[index].quantity;
      if (itemQty > 1) {
        updateInCart[index].quantity -= 1;
      }
      else {
        updateInCart.splice(index, 1);
      };

      if (updateInCart.length < 1 && this.state.originalCart.products.length < 1) this.setState({ renderedComponent: screenList.PRODUCT_LIST });
      pushCartChangeToGtm(product, customData.currency.name, "removedFromCart");
    }

    this.setOriginalCart({ products: updateInCart });
  };

  switchComponent = (cmp) => {
    this.setState({ renderedComponent: cmp });
  };

  switchOnlinePayment = (state) => {
    this.setState({ onlinePayment: state });
  };

  addNewFavourites = () => {
    const favouriteItems = (!this.state.favouriteItems || null) ?
      { name: "Obľúbené", products: [], addons: [] } :
      this.state.favouriteItems;
    const inCart = this.getOriginalCart().products ?? [];
    if (inCart.length > 0) inCart.forEach(item => {
      if ((this.state.menuProductIds.findIndex(mpi => mpi === item.id) < 0)) {
        const index = favouriteItems.products.findIndex(x => x.id === item.id);
        if (index > -1) favouriteItems.products[index].quantity += item.quantity;
        else favouriteItems.products.push(item);
      }

    });

    this.setLocalStorageItem("favouriteItems", favouriteItems);
    this.setOriginalCart({}, true);
  };

  noteHandler = () => {
    const notes = [];
    (this.state.selectedDeliveryOption && this.state.selectedPaymentType.id === "PayOnDelivery") &&
      notes.push(`[${this.state.selectedDeliveryOption}]`);
    this.state.localFormData.note.length > 0 && notes.push(this.state.localFormData.note);

    return notes.join(", ");
  }

  getOrderData(formData) {
    const order =
    {
      price: this.state.price,
      customer:
      {
        email: formData.email,
        phone: formData.phone,
        firstName: formData.firstName,
        lastName: formData.lastName
      },
      address: this.state.selectedAddress,
      cart: this.state.cart,
      originalCart: this.getOriginalCart(),
      note: this.noteHandler()
    };

    return order;
  }

  getLocalFormData = () => {
    const localFormData = {
      firstName: this.getLocalStorageItem("firstName") ?? "",
      lastName: this.getLocalStorageItem("lastName") ?? "",
      phone: this.getLocalStorageItem("phone") ?? "",
      email: this.getLocalStorageItem("email") ?? "",
      note: this.getLocalStorageItem("note") ?? ""
    };
    this.setState({ localFormData });
  }


  formValidationCheck = () => {
    let formException;
    let paymentAndDeliveryException;
    const firstName = this.state.localFormData.firstName;
    const lastName = this.state.localFormData.lastName;
    const phone = this.state.localFormData.phone;
    const email = this.state.localFormData.email;
    let lastAtPos = (email) && email.lastIndexOf("@");
    let lastDotPos = (email) && email.lastIndexOf(".");
    if (this.state.selectedAddress.id < 1)
      formException = exceptionMessages.emptyAddress;
    if (!(
      lastAtPos < lastDotPos &&
      lastAtPos > 0 && email.indexOf("@@") === -1 &&
      lastDotPos > 2 && (email.length - lastDotPos) > 2))
      formException = exceptionMessages.invalidEmail;
    if (!phone || phone.length === 0) formException = exceptionMessages.invalidPhoneNumber;
    if (!firstName || firstName.length === 0) formException = exceptionMessages.emptyName;
    if (!lastName || lastName.length === 0) formException = exceptionMessages.emptyLastName;
    if (!formException && !this.getPayment().id) paymentAndDeliveryException = exceptionMessages.missingPaymentType;
    this.setState({ formException, paymentAndDeliveryException });
    if (this.props.dimensions.width < 1024 && formException) this.switchComponent("contactFormScreen");
    if (formException || paymentAndDeliveryException) return false;
    else return true;
  };

  sendOrderData = (order) => {
    fetch(`${process.env.REACT_APP_BACKEND}/order`, {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(order)
    }
    ).then(res => res.json())
      .then(data => {
        // console.log(data);
        this.setState({ orderResponse: data, connectionFailure: false });
        this.orderRespnsePolicy(data.id, data.paymentData);
        data.id > 0 && pushOrderToGtm(order, data.id);
      }).catch(err => {
        console.log("ERROR: " + err);
        this.setState({ processing: false, connectionFailure: true });
      })
  };

  orderRespnsePolicy = (id, paymentData) => {
    if (id === -6) {
      this.setState({ isOrderSuccess: false });
      this.getOrderException(id);
      this.switchComponent(screenList.CLOSED_SHOP);
    }
    else if (id === -5) {
      this.setState({ isOrderSuccess: false });
      this.getOrderException(id);
      this.switchComponent(screenList.ORDER_STATUS);
    }
    else if (id < 0 && id > -5) {
      this.setState({ isOrderSuccess: false });
      this.getOrderException(id);
    }
    else if (id > 0 && paymentData === null) {
      this.setState({ isOrderSuccess: true });
      this.addNewFavourites();
      this.switchComponent(screenList.ORDER_STATUS);
      this.setOriginalCart({}, true);
    }
    else if (id > 0 && paymentData !== null) {
      this.setState({ isOrderSuccess: true });
      this.switchComponent(screenList.ORDER_STATUS);
      this.addNewFavourites();
    }
    this.setState({ processing: false })
  };

  getOrderException = (id) => {
    let formException;
    let orderException;
    switch (id) {
      case -1:
        formException = exceptionMessages.emptyReqieredData;
        break;
      case -2:
        formException = exceptionMessages.invalidEmail;
        break;
      case -3:
        formException = exceptionMessages.invalidPhoneNumber;
        break;
      case -4:
        formException = exceptionMessages.ivalidAddress;
        break;
      case -5:
        orderException = exceptionMessages.ivalidPrice;
        break;
      case -6:
        orderException = exceptionMessages.shopClosed;
        break;
      default: orderException = exceptionMessages.defaultOrderException;
    }
    if (this.props.dimensions.width < 1024 && formException)
      this.switchComponent(screenList.CONTACT_FORM);
    this.setState({ orderException, formException });
  };

  sendOrder = (formData) => {
    if (this.isShopOpend(this.state.openingHours, this.state.outOfOrder)) {
      this.sendOrderData(this.getOrderData(formData));
    }

    else window.location.reload();
  };

  toggleComponents(cmp) {
    let infoPanel = <InfoPanel
      renderedComponent={this.state.renderedComponent}
      averageDeliveryTimeInMinutes={this.state.averageDeliveryTimeInMinutes}
      outOfOrderCurrently={this.state.outOfOrderCurrently}
      outOfOrder={this.state.outOfOrder}
      deliveryRegionList={this.state.deliveryRegionList}
      preOrderAllowed={this.state.preOrderAllowed}
      isOpened={this.state.isOpened}
    />

    switch (cmp) {
      case "closedShopScreen":
        return (
          <ClosedShop
            openingHours={this.state.openingHours}
            validateCsharpDate={this.validateCsharpDate}
            outOfOrderCurrently={this.state.outOfOrderCurrently}
            outOfOrder={this.state.outOfOrder}
            screenWidth={this.props.dimensions.width}
            preOrderAllowed={this.state.preOrderAllowed}
            switchComponent={this.switchComponent}
            infoPanel={infoPanel}
            deliveryRegionList={this.state.deliveryRegionList}
          />
        );
      case screenList.PRODUCT_LIST:
        return (
          <ProductListScreen
            screenWidth={this.props.dimensions.width}
            categories={this.state.categories}
            price={this.state.price}
            inCart={this.state.cart?.products ?? []}
            changeProductQty={this.changeProductQty}
            currency={this.state.currency}
            selectedDeliveryType={this.getDelivery()}
            selectedPaymentType={this.getPayment()}
            switchComponent={this.switchComponent}
            infoPanel={infoPanel}
          />
        );
      case screenList.ORDER_SUMMARY:
        // HOTFIX -- MUST BE CHANGED
        if (!this.state.deliveries?.length && !this.localAddressId) {
          this.localAddressId = this.getLocalStorageItem("addressId");
          try {
            if (this.localAddressId)
              this.setAddressValues(parseInt(this.localAddressId));
          } catch { }
        }
        // HOTFIX -- MUST BE CHANGED

        return (
          <OrderSummaryScreen
            screenWidth={this.props.dimensions.width}
            inCart={this.state.cart.products}
            currency={this.state.currency}
            price={this.state.price}
            deliveries={this.state.deliveries}
            payments={this.state.payments}
            setDeliveryType={this.setDeliveryType}
            setPaymentType={this.setPaymentType}
            selectedDeliveryType={this.getDelivery()}
            selectedPaymentType={this.getPayment()}
            localFormData={this.state.localFormData}
            formException={this.state.formException}
            paymentAndDeliveryException={this.state.paymentAndDeliveryException}
            getLocalFormData={this.getLocalFormData}
            setAddressValues={this.setAddressValues}
            selectedAddress={this.state.selectedAddress}
            averageDeliveryTimeInMinutes={this.state.averageDeliveryTimeInMinutes}
            clearAddressValues={this.clearAddressValues}
            setLocalStorageItem={this.setLocalStorageItem}
            infoPanel={infoPanel}
            selectedDeliveryOption={this.state.selectedDeliveryOption}
            setDeliveryOption={this.setDeliveryOption}
          />
        );
      case screenList.CART:
        return (
          <CartScreen
            screenWidth={this.props.dimensions.width}
            inCart={this.state.cart.products}
            price={this.state.price}
            currency={this.state.currency}
            changeProductQty={this.changeProductQty}
            selectedDeliveryType={this.getDelivery()}
            selectedPaymentType={this.getPayment()}
            switchComponent={this.switchComponent}
            infoPanel={infoPanel}
          />
        );
      case screenList.CONTACT_FORM:
        return (
          <ContactFormScreen
            setLocalStorageItem={this.setLocalStorageItem}
            localFormData={this.state.localFormData}
            selectedDeliveryType={this.state.cart.delivery}
            selectedPaymentType={this.state.cart.payment}
            formException={this.state.formException}
            getLocalFormData={this.getLocalFormData}
            setAddressValues={this.setAddressValues}
            selectedAddress={this.state.selectedAddress}
            clearAddressValues={this.clearAddressValues}
            infoPanel={infoPanel}
          />
        );
      case screenList.ORDER_STATUS:
        return (
          <OrderStatusScreen
            screenWidth={this.props.dimensions.width}
            isOrderSuccess={this.state.isOrderSuccess}
            orderResponse={this.state.orderResponse}
            orderException={this.state.orderException}
            switchComponent={this.switchComponent}
            addNewFavourites={this.addNewFavourites}
            currency={this.state.currency}
            deliveryRegionList={this.state.deliveryRegionList}
          />
        );
      case screenList.PAYMENT_STATUS:
        return (
          <OnlinePaymentStatusScreen
            screenWidth={this.props.dimensions.width}
            switchComponent={this.switchComponent}
            addNewFavourites={this.addNewFavourites}
            paymentSuccess={this.state.paymentSuccess}
            switchOnlinePayment={this.switchOnlinePayment}
          />
        );
      case screenList.FEEDBACK:
        return (
          <FeedbackScreen />
        )
      default:
        return null;
    };
  };

  toggleFooter(cmp, t) {
    switch (cmp) {
      case screenList.PRODUCT_LIST:
        return (
          <Row>
            <Col></Col>
            <Col>
              <Row>
                <p style={{ fontSize: 11, margin: 0, textAlign: "center", width: "100%" }}>{t("Spolu").toUpperCase()}</p>
              </Row>
              <Row>
                <p style={{ margin: 0, textAlign: "center", width: "100%" }}>
                  {(this.state.price -
                    (this.getDelivery().price + this.getPayment().price)
                  ).toFixed(2)} {this.state.currency}
                </p>
              </Row>
            </Col>
            <Col>
              <Button className="button-primary" variant="light" onClick={() => this.switchComponent(screenList.CART)}>
                {t("Košík").toUpperCase()} <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
              </Button>
            </Col>
          </Row>
        );
      case screenList.CART:
        return (
          <Row>
            <Col xs={4}>
              <Button className="button-secondary" variant="light" onClick={() => this.switchComponent(screenList.PRODUCT_LIST)}>
                <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon> {t("Späť").toUpperCase()}
              </Button>
            </Col>
            <Col>
              <Row>
                <p style={{ fontSize: 11, margin: 0, textAlign: "center", width: "100%" }}>{t("Spolu").toUpperCase()}</p>
              </Row>
              <Row>
                <p style={{ margin: 0, textAlign: "center", width: "100%" }}>
                  {(this.state.price - (this.getDelivery().price + this.getPayment().price)
                  ).toFixed(2)} {this.state.currency}
                </p>
              </Row>
            </Col>
            <Col xs={4}>
              <Button className="button-primary" variant="light" onClick={() => this.switchComponent(screenList.CONTACT_FORM)}>
                {t("Objednať").toUpperCase()} <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
              </Button>
            </Col>
          </Row>
        );
      case screenList.CONTACT_FORM:
        return (
          <Row>
            <Col xs={4}>
              <Button className="button-secondary" variant="light" onClick={() => this.switchComponent(screenList.CART)}>
                <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon> {t("Späť").toUpperCase()}
              </Button>
            </Col>
            <Col>
              <Row>
                <p style={{ fontSize: 11, margin: 0, textAlign: "center", width: "100%" }}>{t("Spolu").toUpperCase()}</p>
              </Row>
              <Row>
                <p style={{ margin: 0, textAlign: "center", width: "100%" }}>
                  {(this.state.price -
                    (this.getDelivery().price + this.getPayment().price)
                  ).toFixed(2)} {this.state.currency}
                </p>
              </Row>
            </Col>
            <Col xs={4}>
              <Button className="button-primary" variant="light" onClick={() => this.state.selectedAddress.id > 0 && this.switchComponent(screenList.ORDER_SUMMARY)}>
                {this.state.selectedAddress.id > 0 ? t("Ďalej").toUpperCase() : t("skontrolujte doručenie").toUpperCase()}{" "}
                {this.state.selectedAddress.id > 0 && <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>}</Button>
            </Col>
          </Row>
        );
      case screenList.ORDER_SUMMARY:
        return (
          <>
            <Row>
              <Col xs={4} onClick={() => this.switchComponent(screenList.CONTACT_FORM)}>
                <Button className="button-secondary" variant="light">
                  <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon> {t("Späť ").toUpperCase()}
                </Button>
              </Col>
              <Col>
                <Row>
                  <p style={{ fontSize: 11, margin: 0, textAlign: "center", width: "100%" }}>{t("Spolu").toUpperCase()}</p>
                </Row>
                <Row>
                  <p style={{ margin: 0, textAlign: "center", width: "100%" }}>
                    {this.state.price.toFixed(2)} {this.state.currency}
                  </p>
                </Row>
              </Col>
              {
                this.state.processing ?
                  <Col xs={4} >
                    <Spinner animation="grow" variant="waning" />
                  </Col> :
                  <Col xs={4} onClick={() => (this.formValidationCheck()) && (!this.state.processing && (this.sendOrder(this.state.localFormData) + this.setState({ processing: true })))}>
                    <Button className="button-primary" variant={this.state.selectedAddress.id > 0 ? "light" : "secondary"} xs={12}>
                      {this.state.selectedAddress.id > 0 ? t("Objednať").toUpperCase() : t("skontrolujte doručenie").toUpperCase()}</Button>
                    <p style={{ fontSize: "11px", fontWeight: "normal", margin: 0 }}>
                      {this.state.selectedAddress.id > 0 && t("s povinnosťou platby")} </p>
                  </Col>
              }
            </Row>

            {this.state.connectionFailure &&
              <Row className="mr-2 ml-2" style={{ justifyContent: "center" }}>
                <p>{exceptionMessages.connectionFailed}</p>
                <FontAwesomeIcon className="mr-2" icon={faExclamationCircle} />
              </Row>
            }
          </>
        );
      default:
        return null;
    }
  }

  render() {
    const t = this.props.translations;
    const renderedComponent = this.state.renderedComponent;
    const screenWidth = this.props.dimensions.width;

    return (
      <>
        <Container fluid={(screenWidth > 1024) ? false : true} className="main-content">
          <Header screenWidth={screenWidth} />
          {
            this.state.onlinePayment ?

              <OnlinePaymentStatusScreen
                screenWidth={screenWidth}
                switchComponent={this.switchComponent}
                addNewFavourites={this.addNewFavourites}
                paymentSuccess={this.state.paymentSuccess}
              /> :

              (!renderedComponent || this.state.processing) ?
                <Spinner className="mt-5" animation="border" /> :
                this.toggleComponents(renderedComponent)
          }

          {(screenWidth >= 1024 && renderedComponent === screenList.ORDER_SUMMARY) &&
            <Row className="button-container ml-1 mr-1 pt-3 pb-3">
              <Col xs={6}>
                <Button className="button-secondary m-auto" variant="light" onClick={() => this.switchComponent(screenList.PRODUCT_LIST)}>
                  <FontAwesomeIcon className="mr-2" icon={faArrowAltCircleLeft}></FontAwesomeIcon>{t("Späť")}
                </Button>
              </Col>
              <Col xs={6}>
                {
                  this.state.processing ? <Spinner animation="grow" variant="light" /> :
                    <>
                      <Button
                        className="button-primary m-auto"
                        variant={this.state.selectedAddress.id > 0 ? "light" : "secondary"}
                        onClick={() => (this.formValidationCheck()) && (!this.state.processing && (this.sendOrder(this.state.localFormData) + this.setState({ processing: true })))}>
                        {this.state.selectedAddress.id > 0 ? t("Objednať s povinnosťou platby") : t("skontrolujte doručnie").toUpperCase()}
                      </Button>
                      {this.state.connectionFailure &&
                        <Alert variant="danger" className="price rounded-0 mt-3">
                          <FontAwesomeIcon className="mr-2" icon={faExclamationCircle} />
                          {t(exceptionMessages.connectionFailed)}
                        </Alert>}
                    </>
                }

              </Col>
            </Row>

          }
          {screenWidth >= 1024 &&
            <Footer deliveryRegionList={this.state.deliveryRegionList} screenWidth={screenWidth} />}

        </Container>
        {(this.state.cart?.products?.length > 0) && (screenWidth < 1024) &&
          <div className="small-screen-footer"> {this.toggleFooter(renderedComponent, t)}</div>
        }

      </>
    );
  }
}

export default Gastrofy;
