import React from "react";
import CartOverviewItem from "./CartOverviewItem";
import CartDiscountedOverviewItem from "./CartDiscountOverviewItem";
import CartGiftOverviewItem from "./CartGiftOverviewItem";
import { Row, Col, ListGroup, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CartOverwiev = (props) => {
    const { t } = useTranslation();

    const inCart = props.inCart;

    const deliveryPrice = props.selectedDeliveryType.price;
    const paymentPrice = props.selectedPaymentType.price;
    const regularItems = inCart && inCart.filter(prod => !prod.tags || !prod.tags.includes("discount") || !prod.tags.includes("gift"));
    const inCartDiscounted = inCart && inCart.filter(prod => prod.tags && prod.tags.includes("discount"));
    const inCartGift = inCart && inCart.filter(prod => prod.tags && prod.tags.includes("gift"));

    let items = regularItems && regularItems?.map((item, i) => {
        const { id } = item;
        return (
            <CartOverviewItem
                item={item}
                key={id}
                currency={props.currency}
            />
        );
    });

    let discItems = inCartDiscounted && inCartDiscounted?.map((disc, i) => {
        const { name } = disc;
        return (
            <CartDiscountedOverviewItem
                item={disc}
                key={name}
                currency={props.currency}
            />
        );
    });

    let giftItems = inCartGift && inCartGift.map((disc, i) => {
        const { name } = disc;
        return (
            <CartGiftOverviewItem
                item={disc}
                key={name}
                currency={props.currency}
            />
        );
    });

    return (
        <Card className="cart-overview rounded-0">
            <Card.Header className="rounded-0">{t("Prehľad košíka").toUpperCase()}</Card.Header>
            <div className="cart-overview-content">
                <ListGroup variant="flush">
                    {items}
                    {discItems}
                    {giftItems}
                    {(deliveryPrice > 0) &&
                        <ListGroup.Item className="cart-item">
                            <Col xs={12}>
                                <Row>
                                    <Col xs={2} className="cart-item-qty">1x</Col>
                                    <Col xs={7} className="cart-item-name">{t(props.selectedDeliveryType.name)}</Col>
                                    <Col xs={3} className="cart-item-price">{(props.selectedDeliveryType.price).toFixed(2)} {props.currency}</Col>
                                </Row>
                            </Col>
                        </ListGroup.Item>
                    }
                    {(paymentPrice > 0) &&
                        <ListGroup.Item className="cart-item">
                            <Col xs={12}>
                                <Row>
                                    <Col xs={2} className="cart-item-qty">1x</Col>
                                    <Col xs={7} className="cart-item-name">{t(props.selectedPaymentType.id === "PayOnDelivery" ? "Platba u kuriéra" : "Online platba")}</Col>
                                    <Col xs={3} className="cart-item-price">{(props.selectedPaymentType.price).toFixed(2)} {props.currency}</Col>
                                </Row>
                            </Col>
                        </ListGroup.Item>
                    }
                </ListGroup>
                <Col className="price">
                    <Row>
                        <Col className="price-title">
                            {t("Spolu").toUpperCase()}:
                        </Col>
                    </Row>
                    <Row>
                        <Col className="price-sum">
                            {(props.price).toFixed(2)} {props.currency}
                        </Col>
                    </Row>
                </Col>
            </div>
        </Card>
    );
};

export default CartOverwiev;
