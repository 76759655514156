import * as commonFunctions from "../functions/commonFunctions";

// event is dynamic Based on action "addedToCart" / "removedFromCart" / "cartItemIncreased"
export function pushCartChangeToGtm(product, currencyName, action) {
  if (!window.dataLayer)
    return;

  window.dataLayer.push({
    event: action,
    ecommerce: {
      currencyCode: currencyName,
      add: {
        products: [{
          name: product.name,
          id: product.id,
          price: (commonFunctions.getProductPrice(product)),
          category: product.productsInfo.category,
          quantity: 1
        }]
      }
    }
  });
};

export function pushOrderToGtm(orderData, orderId) {
  if (!window.dataLayer)
    return;

  const orderedProducts = [];

  orderData.originalCart.products.forEach(product => {
    let gtmProduct =
    {
      name: product.name,
      id: product.id,
      price: commonFunctions.getProductPrice(product),
      category: product.productsInfo.category,
      quantity: product.quantity,
    }
    orderedProducts.push(gtmProduct);
  });

  window.dataLayer.push({
    event: "newOrder",
    ecommerce: {
      purchase: {
        actionField: {
          id: orderId,
          affiliation: process.env.REACT_APP_RESTAURANT_NAME,
          revenue: orderData.price,
          tax: (orderData.originalCart.delivery.price * parseInt(process.env.REACT_APP_LOCAL_TAX)) / 100,
          shipping: orderData.originalCart.delivery,
          payment: orderData.originalCart.payment,
          coupon: ''
        },
        products: orderedProducts
      }
    }
  });
}



